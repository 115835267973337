const state = {
    baseFields: "?per_page=12&_fields[]=slug&_fields[]=id&_fields[]=name",
};

const getters = {};

const actions = {
    fetchPeriodes({ state, dispatch }, params) {
        let strParams = state.baseFields;
        if (params != undefined && params.lenght > 0) {
            strParams += "_fields[]=" + params.join("&_fields[]=");
        }
        return dispatch("Api/get", ["periode" + strParams, {}], {
            root: true,
        });
    },
    fetchPeriode({ state, dispatch }, [id, params]) {
        let strParams = state.baseFields;
        if (params != undefined && params.lenght > 0) {
            strParams += "&_fields[]=" + params.join("&_fields[]=");
        }
        return dispatch("Api/get", ["periode/" + id + strParams], {
            root: true,
        });
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
