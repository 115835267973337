const state = {
    activeSeason: "",
    orderSeason: ["winter", "spring", "summer", "autumn"],
    seasons: {
        winter: {
            id: "winter",
            src: require("../../assets/img/circles/hiver.svg"),
            //src: require("../../assets/img/circles/hiver.png"),
            monthsName: ["janvier", "février", "mars"],
            monthsOrder: [12, 1, 2, 3, 4],
            idApi: [],
            solsticeName: "",
            wendatName: "ohcha'",
            frName: "hiver",
            order: 0,
        },
        spring: {
            id: "spring",
            src: require("../../assets/img/circles/printemps.svg"),
            //src: require("../../assets/img/circles/printemps.png"),
            monthsName: ["avril", "mai", "juin"],
            monthsOrder: [3, 4, 5, 6, 7],
            idApi: [],
            solsticeName: "solstice d'été",
            wendatName: "yayenra'",
            frName: "printemps",
            order: 1,
        },
        summer: {
            id: "summer",
            src: require("../../assets/img/circles/ete.svg"),
            //src: require("../../assets/img/circles/ete.png"),
            monthsName: ["juillet", "août", "septembre"],
            monthsOrder: [6, 7, 8, 9, 10],
            idApi: [],
            solsticeName: "",
            wendatName: "yayennya'",
            frName: "été",
            order: 2,
        },
        autumn: {
            id: "autumn",
            src: require("../../assets/img/circles/automne.svg"),
            //src: require("../../assets/img/circles/automne.png"),
            monthsName: ["octobre", "novembre", "décembre"],
            monthsOrder: [9, 10, 11, 12, 1],
            idApi: [],
            solsticeName: "solstice d'hiver",
            wendatName: "yanenda'yeh",
            frName: "automne",
            order: 3,
        },
    },
};

const getters = {
    activeSeason(state) {
        return state.seasons[state.activeSeason];
    },
    next(state) {
        let baseOrder = state.seasons[state.activeSeason].order;
        let nextOrder = baseOrder + 1;
        if (nextOrder > 3) nextOrder = 0;
        return state.seasons[state.orderSeason[nextOrder]];
    },
    prev(state) {
        let baseOrder = state.seasons[state.activeSeason].order;
        let prevOrder = baseOrder - 1;
        if (prevOrder < 0) prevOrder = 3;
        return state.seasons[state.orderSeason[prevOrder]];
    },
    monthsSeason(state) {
        return state.seasons[state.activeSeason].monthsOrder;
    },
    idApiMonthsSeason(state) {
        return state.seasons[state.activeSeason].idApi;
    },
};

const actions = {
    select({ commit }, activeSeason) {
        commit("setActiveSeason", activeSeason);
    },
    changeHover({ commit }, activeSeason) {
        let imgActive = undefined;
        if (activeSeason != undefined) {
            imgActive = state.seasons[activeSeason].src;
        }
        commit("setCircleHover", imgActive);
    },
    createPeriodeMonthIdApi({ commit, rootState }) {
        for (const key in state.seasons) {
            let periodeMonthIdApi = [];
            for (const key2 in state.seasons[key].monthsOrder) {
                periodeMonthIdApi.push(
                    rootState.Periodes.periodes[
                        state.seasons[key].monthsOrder[key2]
                    ].id
                );
            }
            commit("setKeyMutation", key);
            commit("addPeriodeMonthIdApi", periodeMonthIdApi);
        }
    },
};

const mutations = {
    setActiveSeason(state, activeSeason) {
        state.activeSeason = activeSeason;
    },
    setCircleHover(state, imgActiveSeason) {
        if (imgActiveSeason == undefined && state.activeSeason != "") {
            imgActiveSeason = state.seasons[state.activeSeason].src;
        }
        state.circleHover = imgActiveSeason;
    },
    setKeyMutation(state, key) {
        state.key = key;
    },
    addPeriodeMonthIdApi(state, arrIdApi) {
        state.seasons[state.key].idApi = arrIdApi;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
