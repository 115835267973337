import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import vuetify from "./class/Vuetify.js";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(vuetify, {
    iconfont: "mdi",
});

Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
    if (
        (to.name == "Timeline" ||
            to.name == "Activity" ||
            to.name == "ActivityDetail") &&
        (store.state.Seasons.activeSeason == undefined ||
            store.state.Thematics.activeThematic == undefined)
    ) {
        next({ path: "/circleUniv" });
    } else {
        next();
    }
});
new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
