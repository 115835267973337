const state = {
    baseFields:
        "?per_page=100&_fields[]=id&_fields[]=title&_fields[]=content&_fields[]=excerpt&_fields[]=periode&_fields[]=acf",
};

const getters = {};

const actions = {
    fetchActivitys({ state, dispatch }, [idThematic, idPeriode, params]) {
        let strParams =
            state.baseFields +
            "&thematique=" +
            idThematic +
            "&periode=" +
            idPeriode;
        if (params != undefined && params.lenght > 0) {
            strParams += "&_fields[]=" + params.join("&_fields[]=");
        }
        return dispatch("Api/get", ["activity" + strParams, {}], {
            root: true,
        });
    },

    fetchActivity({ state, dispatch }, [id, params]) {
        let strParams = state.baseFields;
        if (params != undefined && params.lenght > 0) {
            strParams += "&_fields[]=" + params.join("&_fields[]=");
        }
        return dispatch("Api/get", ["activity/" + id + strParams], {
            root: true,
        });
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
