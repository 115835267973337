<template>
    <div>
        <div
            id="circleUnivCirclePrincipal"
            :style="{ 'background-image': 'url(' + this.circleBase + ')' }"
        >
            <div
                class="circleUnivCircleHover"
                v-if="this.circleHover == undefined"
            >
                <season
                    v-for="(thisSeason, idSeason) in this.seasonsPosition"
                    :key="idSeason"
                    :idSeason="idSeason"
                ></season>
            </div>
            <div
                class="circleUnivCircleHover"
                v-if="this.circleHover != undefined"
                :style="{
                    'background-image': 'url(' + this.circleHover + ')',
                }"
            >
                <div
                    class="circleUnivCircleHover"
                    v-if="this.circleThematicHover == undefined"
                >
                    <season
                        v-for="(thisSeason, idSeason) in this.seasonsPosition"
                        :key="idSeason"
                        :idSeason="idSeason"
                    ></season>
                </div>
                <div
                    class="circleUnivCircleHover"
                    v-if="this.circleThematicHover != undefined"
                    :style="{
                        'background-image':
                            'url(' + this.circleThematicHover + ')',
                    }"
                >
                    <season
                        v-for="(thisSeason, idSeason) in this.seasonsPosition"
                        :key="idSeason"
                        :idSeason="idSeason"
                    ></season>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
#circleUnivCirclePrincipal {
    position: relative;
    background-size: 100% 100%;
    right: 10%;
    width: 80%;
    height: 80%;
}
.circleUnivCircleHover {
    position: relative;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    z-index: 2;
}
</style>

<script>
import { mapState } from "vuex";
import Season from "./Season";

export default {
    name: "CircleUnivCircle",

    components: {
        season: Season,
    },

    computed: {
        ...mapState({
            seasonsPosition: (state) => state.CircleUniv.seasons,
            circleBase: (state) => state.CircleUniv.circleBase,
            circleHover: (state) => state.CircleUniv.circleHover,
            circleThematicHover: (state) => state.Thematics.circleThematicHover,
        }),
    },
};
</script>
