<template>
    <div class="windowSize" id="turtleContainerPrincipal">
        <div id="turtleContainerTextImg">
            <div id="turlteContainterImg">
                <v-img
                    id="turlteImgWater"
                    contain
                    :src="require('../assets/img/water.svg')"
                >
                    <v-img
                        id="turlteImgTurtle"
                        contain
                        :src="require('../assets/img/turtle.svg')"
                    ></v-img>
                </v-img>
            </div>
            <div id="turtleContainerText">
                <span id="turtleTitle">yändia’wich</span>
                <span id="turtleSubTitle">LA TORTUE</span>
                <v-skeleton-loader
                    v-if="this.content == ''"
                    type="article"
                    class="turtleText"
                ></v-skeleton-loader>
                <p class="turtleText" v-html="this.content"></p>
                <div id="turtleKnowMore">
                    <v-dialog
                        v-model="dialog"
                        transition="dialog-top-transition"
                        content-class="turtleDialog"
                        hide-overlay
                        width="1300"
                        height="800"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <span
                                id="turtleKnowMoreText"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-img
                                    id="turtleKnowMoreImg"
                                    contain
                                    :src="
                                        require('../assets/img/nav/+signTurtle.svg')
                                    "
                                ></v-img>
                                EN SAVOIR PLUS
                            </span>
                        </template>

                        <div id="turtleDialogBorderExterne">
                            <div id="turtleDialogBorderInterne">
                                <div id="turtleDialogTopDiv">
                                    <div id="turtleDialogTopLeftDiv">
                                        <span class="turtleDialogTitle">
                                            Informations complémentaire
                                        </span>
                                        <v-skeleton-loader
                                            v-if="this.dialogInfoCompl == ''"
                                            type="article"
                                            class="turtleDialogLeftText"
                                        ></v-skeleton-loader>
                                        <p
                                            class="turtleDialogLeftText"
                                            v-html="this.dialogInfoCompl"
                                        ></p>
                                        <div id="turtleDialogLeftImgDiv">
                                            <v-img
                                                id="turtleDialogLeftImg"
                                                contain
                                                :src="
                                                    require('../assets/img/tortueIllus.png')
                                                "
                                                width="15vmin"
                                                height="11vmin"
                                            ></v-img>
                                        </div>
                                    </div>

                                    <div id="turtleDialogTopRightDiv">
                                        <span class="turtleDialogTitle">
                                            Suggestion d’activités
                                        </span>
                                        <v-skeleton-loader
                                            v-if="this.dialogSugg == ''"
                                            type="article"
                                            class="turtleDialogRightText"
                                        ></v-skeleton-loader>
                                        <p
                                            class="turtleDialogRightText"
                                            v-html="this.dialogSugg"
                                        ></p>
                                    </div>
                                </div>
                                <div id="turtleDialogBottomDiv">
                                    <v-img
                                        id="turtleDialogBottomImgLeft"
                                        contain
                                        :src="
                                            require('../assets/img/iconMateriel.svg')
                                        "
                                        width="12.5vmin"
                                        height="12.5vmin"
                                    ></v-img>

                                    <div id="turtleDialogBottomCenter">
                                        <span class="turtleDialogTitle">
                                            Matériel
                                        </span>
                                        <v-skeleton-loader
                                            v-if="this.dialogMaterial == ''"
                                            type="article"
                                            class="turtleDialogBottomText"
                                        ></v-skeleton-loader>
                                        <p
                                            class="turtleDialogBottomText"
                                            v-html="this.dialogMaterial"
                                        ></p>
                                    </div>
                                    <v-img
                                        id="turtleDialogBottomImgRight"
                                        contain
                                        :src="
                                            require('../assets/img/visitezCulturePatrimoine.svg')
                                        "
                                        width="20vmin"
                                        height="20vmin"
                                    ></v-img>
                                </div>
                            </div>
                        </div>

                        <div
                            id="turtleDialogBtnsEnters"
                            @click="dialog = false"
                        >
                            <v-img
                                id="turtleDialogImgRightArrow"
                                contain
                                :src="
                                    require('../assets/img/nav/arrow/leftArrow.svg')
                                "
                                width="9vmin"
                                height="5vmin"
                            >
                            </v-img>
                            <div id="turtleDialogBtnsEntersText">
                                <span id="turtleDialogBtnEnterFr">RETOUR</span>
                            </div>
                        </div>
                    </v-dialog>
                </div>
            </div>
            <div id="turtleBtnsEnters" @click="$router.push('/Woman')">
                <v-img
                    id="turtleImgRightArrow"
                    contain
                    :src="require('../assets/img/nav/arrow/upArrow.svg')"
                    width="5vmin"
                    height="10vmin"
                >
                </v-img>
                <div id="turtleBtnsEntersText">
                    <span id="turtleBtnEnterFr">POURSUIVRE</span>
                </div>
            </div>
        </div>
        <appFooter :mode="1"></appFooter>
    </div>
</template>

<style>
#turtleContainerPrincipal {
}
#turtleContainerTextImg {
    display: flex;
    flex-flow: center;
    align-items: center;
    height: 85%;
}
#turlteContainterImg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    height: 100%;
}
#turlteImgWater {
    width: 100%;
}
#turlteImgTurtle {
    margin: 0px auto;
    width: 55%;
}
#turlteImgWater {
    display: flex;
    align-items: center;
    justify-content: center;
}
#turtleContainerText {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 10px;
    width: 45%;
}
#turtleTitle {
    font-family: "Vollkorn-SemiBoldItalic";
    color: #74924e;
    font-size: 6vmin;
}
#turtleSubTitle {
    font-family: "Montserrat-Light";
    color: #745852;
    font-size: 3.5vmin;
    text-transform: uppercase;
}
.turtleText {
    text-align: center;
    font-family: "SourceSerifPro-Regular";
    color: #745852;
    font-size: 2vmin;
    margin-top: 5%;
    width: 85%;
}
#turtleKnowMore {
    display: flex;
    flex-flow: row;
    align-items: center;
}
#turtleKnowMore:hover {
    opacity: 0.65;
    transition-duration: 250ms;
}
#turtleKnowMoreImg {
    width: 25px;
    margin-right: 7.5px;
}
#turtleKnowMoreText {
    display: flex;
    flex-flow: row;
    align-items: center;
    font-family: "Montserrat-Black";
    color: #74924e;
    font-size: 1.5vmin;
    margin-left: 10px;
    letter-spacing: 2px;
}
#turtleBtnsEnters {
    display: flex;
    flex-flow: column;
    align-items: center;
    position: absolute;
    top: 35%;
    right: 20px;
    width: 10%;
    cursor: pointer;
    background-color: white;
}
#turtleBtnEnterFr {
    font-family: "Montserrat-Black";
    color: #a78a7f;
    text-transform: uppercase;
    font-size: 1.5vmin;
    letter-spacing: 1.5px;
}
#turtleImgRightArrow {
    margin: 10px;
}
/***** Dialog *****/
.turtleDialog {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
#turtleDialogBorderExterne {
    position: absolute;
    top: 15px;
    left: 5%;
    margin: 0 auto;
    padding: 4px;
    background-color: white;
    border: #e7d7c2 solid 2.5px;
    width: 92%;
    height: 85%;
}
#turtleDialogBorderInterne {
    display: flex;
    flex-flow: column;
    align-items: center;
    border: #e7d7c2 solid 5.5px;
    padding: 30px;
    height: 100%;
}
.turtleDialogTitle {
    font-family: "Cinzel-Black";
    color: #a78a7f;
    font-size: 2.5vmin;
}
#turtleDialogTopDiv {
    display: flex;
    flex-flow: row;
    width: 100%;
    height: 70%;
}
#turtleDialogTopLeftDiv {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 4% 4% 0% 4%;
    margin: 1%;
    width: 50%;
}
.turtleDialogLeftText {
    font-family: "SourceSerifPro-Regular";
    color: #745852;
    font-size: 1.6vmin;
    margin-top: 4%;
    text-align: center;
    width: 90%;
}
#turtleDialogLeftImgDiv {
    display: flex;
    align-items: center;
}
#turtleDialogLeftImg {
    width: 5%;
}
#turtleDialogTopRightDiv {
    display: flex;
    flex-flow: column;
    background-image: url("../assets/img/cadreActivités.svg");
    margin-top: 2%;
    padding: 5% 1% 1% 1%;
    width: 50%;
}
.turtleDialogRightText {
    text-transform: uppercase;
    font-family: "Montserrat-SemiBold";
    color: #a78a7f;
    font-size: 1.3vmin;
    width: 72%;
    line-height: 1.5vmin;
    text-transform: uppercase;
}
.turtleDialogRightText li {
    margin-top: 10px;
}
#turtleDialogBottomDiv {
    display: flex;
    flex-flow: row;
    align-items: center;
    background-color: #f9f5f0;
    padding: 30px;
    width: 95%;
    height: 30%;
}
#turtleDialogBottomCenter {
    padding: 30px;
    width: 80%;
}
.turtleDialogBottomTitle {
    font-family: "Cinzel-Black";
    color: #a78a7f;
    font-size: 2vmin;
    margin: 20px;
}
.turtleDialogBottomText {
    font-family: "Montserrat-SemiBold";
    color: #a78a7f;
    font-size: 1.5vmin;
}
.turtleDialogBottomText li {
    margin-top: 10px;
}
.turtleDialogBottomText li a {
    text-decoration: none;
    font-family: "Montserrat-SemiBold";
    color: #a78a7f;
}
#turtleDialogBtnsEnters {
    display: flex;
    flex-flow: row;
    align-items: center;
    position: absolute;
    top: 40%;
    left: 0px;
    width: 10%;
    height: 10%;
    cursor: pointer;
    background-color: white;
}
#turtleDialogBtnEnterFr {
    font-family: "Montserrat-Black";
    color: #a78a7f;
    font-size: 2vmin;
}
#turtleDialogImgRightArrow {
    margin: 5px;
    width: 5%;
}
</style>

<script>
import { mapState } from "vuex";
import store from "../store";

import appFooter from "./appFooter";

export default {
    name: "Turtle",

    data() {
        return { dialog: false };
    },

    components: {
        appFooter: appFooter,
    },

    mounted() {
        store.dispatch("Turtle/getTurtleDatas");
    },

    computed: {
        ...mapState({
            content: (state) => state.Turtle.content,
            dialogInfoCompl: (state) => state.Turtle.dialogInfoCompl,
            dialogSugg: (state) => state.Turtle.dialogSugg,
            dialogMaterial: (state) => state.Turtle.dialogMaterial,
        }),
    },
};
</script>
