<template>
    <div id="activityDetailPrincipal">
        <div
            id="activityDetailThematicTitle"
            :style="{
                'background-color': this.activeThematicColor,
            }"
            v-html="this.activeThematicName"
        ></div>
        <div id="activityDetailContentCenter">
            <div id="activityDetailRightTitle" v-html="this.title"></div>
            <div
                id="activityDetailRightSubTitle"
                v-html="'De ' + this.periodesNames"
            ></div>
            <div id="activityDetailContentContainer">
                <v-skeleton-loader
                    v-if="this.content == undefined"
                    type="article"
                    class="activityDetailContent"
                ></v-skeleton-loader>
                <div class="activityDetailContent" v-html="this.content"></div>
            </div>
        </div>
        <div id="activityDetailFooterForceSpace">&nbsp;</div>
        <appFooter :mode="3"></appFooter>
    </div>
</template>

<style>
#activityDetailPrincipal {
    display: flex;
    flex-flow: column;
    align-items: center;
    background-color: rgb(249, 245, 240, 1);
    width: 100vw;
    height: 100vh;
}
#activityDetailThematicTitle {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Cinzel-Black";
    color: #ffffff;
    font-size: 6vmin;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    height: 150px;
    z-index: 1;
}
#activityDetailContentCenter {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    position: relative;
    top: 150px;
    width: 80%;
    height: 100%;
    padding: 4%;
    overflow: scroll !important;
}
#activityDetailRightTitle {
    font-family: "Cinzel-Bold";
    color: #745852;
    font-size: 27pt;
    text-align: center;
    width: 80%;
}
#activityDetailRightSubTitle {
    font-family: "Montserrat-Light";
    color: #745852;
    font-size: 18pt;
    text-transform: uppercase;
    margin-bottom: 4%;
}
#activityDetailContentContainer {
    width: 80%;
    height: 100%;
}
.activityDetailContent {
    width: 100%;
    height: 100%;
}
#activityDetailFooterForceSpace {
    height: 290px;
}
</style>

<script>
import { mapState, mapGetters } from "vuex";
import appFooter from "./appFooter";

export default {
    name: "ActivityDetail",

    components: {
        appFooter: appFooter,
    },

    computed: {
        ...mapState({
            title: (state) => state.Activity.title,
            content: (state) => state.Activity.content,
            periodesNames: (state) => state.Activity.periodesNames,
        }),
        ...mapGetters({
            activeThematicName: "Thematics/activeName",
            activeThematicColor: "Thematics/activeColor",
        }),
    },
};
</script>
