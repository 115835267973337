<template>
    <div
        class="windowSize"
        id="timelineRowActivitysPrincipal"
        :style="{ height: 37 / this.nbRow + 'vh' }"
    >
        <div
            v-for="(activity, idRangePosition) in this.activitys"
            :key="idRangePosition"
            id="timelineRowActivitys"
            :style="{
                width:
                    (!activity.hasOwnProperty('emptySpace')
                        ? activity.lenActivity
                        : '33.5') + '%',
            }"
        >
            <timelineActivity
                v-if="!activity.hasOwnProperty('emptySpace')"
                :idRangePosition="idRangePosition"
                :activity="activity"
                :class="{
                    timelineActivity: true,
                    roundStartEnd: activity.roundStart && activity.roundEnd,
                    roundStart: activity.roundStart && !activity.roundEnd,
                    roundEnd: !activity.roundStart && activity.roundEnd,
                }"
            />
            <div
                v-if="activity.hasOwnProperty('emptySpace')"
                class="emptySpace"
            >
                &nbsp;
            </div>
        </div>
    </div>
</template>

<style scoped>
#timelineRowActivitysPrincipal {
    display: flex;
    flex-flow: row;
    width: 100%;
    max-height: 37px;
    margin-top: 10px;
    margin-bottom: 10px;
}
#timelineRowActivitys {
    display: flex;
    flex-flow: row;
}
.timelineActivity {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    background-color: rgb(231, 215, 194, 0.5);
    width: 100%;
}
.roundStartEnd {
    border-radius: 30px;
    margin-left: 5px;
    margin-right: 5px;
}
.roundStart {
    border-radius: 30px 0px 0px 30px;
    margin-left: 5px;
}
.roundEnd {
    border-radius: 0px 30px 30px 0px;
    margin-right: 5px;
}
.emptySpace {
    width: 100%;
}
</style>

<script>
import { mapGetters } from "vuex";
import TimelineActivity from "./TimelineActivity";

export default {
    name: "TimelineRowActivitys",

    props: {
        idActivitys: Number,
        activitys: Array,
    },

    computed: {
        ...mapGetters({
            nbRow: "TimelineActivitys/nbRow",
        }),
    },
    components: {
        timelineActivity: TimelineActivity,
    },
};
</script>
