import Vue from "vue";
import Vuex from "vuex";

import Reference from "./modules/Reference";
import Turtle from "./modules/Turtle";
import Woman from "./modules/Woman";
import CircleUniv from "./modules/CircleUniv";
import Seasons from "./modules/Seasons";
import Thematics from "./modules/Thematics";
import Periodes from "./modules/Periodes";
import TimelineActivitys from "./modules/TimelineActivitys";
import Activity from "./modules/Activity";
import Credits from "./modules/Credits";

import Api from "./modules/api/Api";
import PostApi from "./modules/api/PostApi";
import ActivityApi from "./modules/api/ActivityApi";
import PeriodeApi from "./modules/api/PeriodeApi";
import MediaApi from "./modules/api/MediaApi";
import ThematicApi from "./modules/api/ThematicApi";
import ReferenceApi from "./modules/api/ReferenceApi";
import TurtleApi from "./modules/api/TurtleApi";
import WomanApi from "./modules/api/WomanApi";
import CreditsApi from "./modules/api/CreditsApi";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        Reference,
        Turtle,
        Woman,
        CircleUniv,
        Seasons,
        Thematics,
        Periodes,
        TimelineActivitys,
        Activity,
        Credits,
        Api,
        PostApi,
        ActivityApi,
        PeriodeApi,
        MediaApi,
        ThematicApi,
        ReferenceApi,
        TurtleApi,
        WomanApi,
        CreditsApi,
    },
    strict: true,
});
