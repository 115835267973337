<template>
    <div class="windowSize" id="activityBrief">
        <div
            id="activityBriefThematicTitle"
            :style="{
                'background-color': this.activeThematicColor,
            }"
            v-html="this.activeThematicName"
        ></div>
        <div id="activityBriefContent">
            <div id="activityBriefContentLeft">
                <div
                    id="activityBriefLeftImg1"
                    v-if="this.urlImage1 != false"
                    :style="{
                        'background-image': 'url(' + this.urlImage1 + ')',
                    }"
                >
                    &nbsp;
                </div>
                <div
                    id="activityBriefLeftImg2"
                    v-if="this.urlImage2 != false"
                    :style="{
                        'background-image': 'url(' + this.urlImage2 + ')',
                    }"
                >
                    &nbsp;
                </div>
                <div
                    id="activityBriefLeftImg3"
                    v-if="this.urlImage3 != false"
                    :style="{
                        'background-image': 'url(' + this.urlImage3 + ')',
                    }"
                >
                    &nbsp;
                </div>
            </div>
            <div id="activityBriefContentCenter">
                <div id="activityBriefRightTitle" v-html="this.title"></div>
                <div
                    id="activityBriefRightSubTitle"
                    v-html="'De ' + this.periodesNames"
                ></div>
                <v-skeleton-loader
                    v-if="this.excerpt == undefined"
                    type="article"
                    class="activityBriefRightText"
                ></v-skeleton-loader>
                <div class="activityBriefRightText" v-html="this.excerpt"></div>
                <div
                    id="activityBriefRightKnowMore"
                    @click="$router.push('/activityDetail')"
                >
                    <v-img
                        id="activityBriefKnowMoreImg"
                        contain
                        :src="this.activeThematicSign"
                    ></v-img>
                    <span
                        id="activityBriefKnowMoreText"
                        :style="{
                            color: this.activeThematicColor,
                        }"
                    >
                        EN SAVOIR PLUS
                    </span>
                </div>
            </div>
            <div id="activityBriefContentRight">&nbsp;</div>
        </div>
        <appFooter :mode="3"></appFooter>
    </div>
</template>

<style>
#activityBrief {
}
#activityBriefThematicTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Cinzel-Black";
    color: #ffffff;
    font-size: 6vmin;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    height: 14%;
}
#activityBriefContent {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80%;
}
#activityBriefContentLeft {
    z-index: 0;
    background-image: url("../assets/img/bckgrnd_activities.svg");
    background-size: 80%;
    background-position: left center;
    background-color: rgb(249, 245, 240, 1);
    width: 50%;
    height: 100%;
}
#activityBriefLeftImg1 {
    position: absolute;
    background-size: 75%;
    background-position: center center;
    top: 25%;
    left: 5%;
    width: 35%;
    height: 35%;
}
#activityBriefLeftImg2 {
    position: absolute;
    background-size: 45%;
    background-position: left center;
    top: 55%;
    left: 5%;
    width: 25%;
    height: 25%;
}
#activityBriefLeftImg3 {
    position: absolute;
    background-size: 65%;
    background-position: right center;
    top: 55%;
    left: 18%;
    width: 25%;
    height: 25%;
}
#activityBriefContentCenter {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 40%;
}
#activityBriefRightTitle {
    font-family: "Cinzel-Bold";
    color: #745852;
    font-size: 3.5vmin;
    text-align: center;
    width: 80%;
}
#activityBriefRightSubTitle {
    font-family: "Montserrat-Light";
    color: #745852;
    font-size: 2.5vmin;
    text-transform: uppercase;
}
.activityBriefRightText {
    text-align: center;
    font-family: "SourceSerifPro-Regular";
    color: #745852;
    font-size: 2vmin;
    margin-top: 5%;
    width: 74%;
}
#activityBriefRightKnowMore {
    display: flex;
    flex-flow: row;
    align-items: center;
    cursor: pointer;
    width: 30%;
    height: 30px;
}
#activityBriefRightKnowMore:hover {
    opacity: 0.5;
}
#activityBriefKnowMoreImg {
    width: 20px;
    height: 25px;
}
#activityBriefKnowMoreText {
    font-family: "Montserrat-Black";
    color: #745852;
    font-size: 1.5vmin;
    margin-left: 10px;
    letter-spacing: 2px;
}
#activityBriefContentRight {
    background-color: rgb(249, 245, 240, 1);
    width: 10%;
    height: 100%;
}
</style>

<script>
import { mapState, mapGetters } from "vuex";
import store from "../store";
import appFooter from "./appFooter";

export default {
    name: "Activity",

    props: {
        idActivity: Number,
    },
    components: {
        appFooter: appFooter,
    },

    mounted() {
        store.dispatch("Activity/getApiActivity", {
            root: true,
        });
    },

    computed: {
        ...mapState({
            title: (state) => state.Activity.title,
            excerpt: (state) => state.Activity.excerpt,
            periodesNames: (state) => state.Activity.periodesNames,
        }),
        ...mapGetters({
            activeThematicName: "Thematics/activeName",
            activeThematicColor: "Thematics/activeColor",
            activeThematicSign: "Thematics/activeSign",
            urlImage1: "Activity/urlImage1",
            urlImage2: "Activity/urlImage2",
            urlImage3: "Activity/urlImage3",
        }),
    },
};
</script>
