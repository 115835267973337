<template>
    <div class="windowSize" id="timelineActivitysPrincipal">
        <timelineRowActivitys
            v-for="(thisRowActivitys, idActivitys) in this.rowsFullActivitys"
            :key="idActivitys"
            :idActivitys="idActivitys"
            :activitys="thisRowActivitys"
        />
    </div>
</template>

<style scoped>
#timelineActivitysPrincipal {
    position: absolute;
    width: 100%;
    height: 55%;
    padding-top: 3%;
}
</style>

<script>
import { mapState } from "vuex";
import TimelineRowActivitys from "./TimelineRowActivitys";

export default {
    name: "Timeline",

    components: {
        timelineRowActivitys: TimelineRowActivitys,
    },

    computed: {
        ...mapState({
            rowsFullActivitys: (state) =>
                state.TimelineActivitys.rowsFullActivitys,
        }),
    },
};
</script>
