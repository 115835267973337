<template>
    <div>
        <div class="timelineActivity" @click="activityClick(activity.id)">
            <span v-html="this.activity.title"></span>
            {{ this.activeThematic.timelineSignImg }}
            <v-img
                id="timelineActivityBriefImg"
                contain
                :src="this.activeThematicSign"
            ></v-img>
        </div>
    </div>
</template>

<style>
.timelineActivity {
    display: flex;
    flex-flow: row;
    align-items: center;
    text-align: center;
    cursor: pointer;
    font-family: "Montserrat-Medium";
    color: #745852;
    padding: 5px;
    font-size: 1.4vmin;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.timelineActivity:hover {
    opacity: 0.75;
    transition-duration: 250ms;
}
#timelineActivityBriefImg {
    z-index: 1;
    width: 55px;
    height: 35px;
}
</style>

<script>
import { mapState, mapGetters } from "vuex";
import store from "../store";
import router from "../router";

export default {
    name: "TimelineActivity",

    props: {
        idRangePosition: Number,
        activity: Object,
    },

    computed: {
        ...mapState({
            activeThematic: (state) => state.Thematics.activeThematic,
        }),
        ...mapGetters({
            activeThematicSign: "Thematics/activeSign",
        }),
    },

    methods: {
        activityClick(id) {
            store.commit("TimelineActivitys/setActiveActivity", id);
            store.dispatch("Activity/select", id);
            router.push("/activity");
        },
    },
};
</script>
