const state = {};

const getters = {};

const actions = {
    fetchPosts({ dispatch }, params) {
        let strParams = "";
        if (params != undefined && Array.isArray(params) && params.lenght > 0) {
            strParams = "_fields[]=" + params.join("&_fields[]=");
        } else {
            strParams = params;
        }
        return dispatch("Api/get", ["posts" + strParams, {}], { root: true });
    },

    fetchPost({ dispatch }, [id, params]) {
        let strParams = "";
        if (params != undefined && Array.isArray(params) && params.lenght > 0) {
            strParams = "_fields[]=" + params.join("&_fields[]=");
        } else {
            strParams = params;
        }
        return dispatch("Api/get", ["posts/" + id + strParams], {
            root: true,
        });
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
