<template>
    <v-dialog v-model="dialogModal" :width="widthDialog">
        <v-card>
            <div id="dialogTop">
                <div
                    :id="cssName + 'Title'"
                    style="width: 50%"
                    v-html="this.title"
                ></div>
                <div id="dialogBtnClose">
                    <v-btn
                        id="dialogIconClose"
                        icon
                        @click="dialogModal = false"
                    >
                        <v-icon>mdi-close-thick</v-icon>
                    </v-btn>
                </div>
            </div>
            <div id="dialogContent">
                <v-img
                    v-if="this.mode == 'img'"
                    :id="cssName + 'Img'"
                    contain
                    :src="this.contents.src"
                    @click.stop="dialogCall = true"
                ></v-img>
                <div v-if="this.mode == 'info'">
                    <div
                        id="dialogBottom"
                        v-for="(dialogContent, keyArr) in this.contents"
                        :key="keyArr"
                    >
                        <div
                            :id="cssName + 'SubTitle'"
                            v-if="dialogContent.hasOwnProperty('name')"
                            v-html="dialogContent.name"
                        ></div>
                        <div
                            :id="cssName + 'Text'"
                            v-html="dialogContent.description"
                        ></div>
                    </div>
                </div>
            </div>
            <div id="dialogFooter">
                <div v-if="this.footer != ''">
                    <div :id="cssName + 'Footer'" v-html="this.footer"></div>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<style>
#dialogTop {
    position: fixed;
    display: flex;
    flex-flow: row;
    background-image: url("../assets/img/patternBottom.svg");
    background-color: white;
    background-repeat: repeat;
    margin: 0px;
    padding: 10px;
    width: 90%;
    z-index: 2;
}
#dialogTitle {
    display: flex;
    justify-content: flex-start;
    width: 50%;
}
#dialogBtnClose {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}
#dialogIconClose {
    color: #a78a7f;
}
#dialogIconClose:hover {
    color: #745852;
}
#dialogBottom {
    padding: 10px;
    width: 100%;
}
#dialogContent {
    padding-top: 7%;
}
#dialogContent {
}
#dialogSubTitle {
}
#dialogContent {
}
</style>

<script>
export default {
    name: "DialogModal",

    props: {
        widthDialog: String,
        cssName: String,
        title: String,
        footer: String,
        contents: {
            default: [],
        },
        dialogOpen: {
            default: false,
        },
        mode: {
            default: "info",
        },
    },
    computed: {
        dialogModal: {
            get: function () {
                return this.dialogOpen;
            },
            set: function (value) {
                this.$emit("dialogClose", value);
            },
        },
    },
};
</script>
