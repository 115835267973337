import Vue from "vue";
import Router from "vue-router";
import Home from "../components/Home";
import Reference from "../components/Reference";
import Turtle from "../components/Turtle";
import Woman from "../components/Woman";
import CircleUniv from "../components/CircleUniv";
import Timeline from "../components/Timeline";
import Activity from "../components/Activity";
import ActivityDetail from "../components/ActivityDetail";

Vue.use(Router);

const DEFAULT_TITLE = "Univers Wendat ïonyionhwentsou'tenh";
const ROUTER = new Router({
    mode: "history",
    //duplicateNavigationPolicy: "reload",
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from;
        });
    },
    routes: [
        {
            path: "/",
            meta: {
                title: "Accueil - ",
            },
            redirect: {
                name: "Home",
            },
        },
        {
            path: "/home",
            name: "Home",
            meta: {
                title: "Accueil - ",
            },
            component: Home,
        },
        {
            path: "/reference",
            name: "Reference",
            meta: {
                title: "Référence Imprimée - ",
            },
            component: Reference,
        },
        {
            path: "/turtle",
            name: "Turtle",
            meta: {
                title: "La tortue - ",
            },
            component: Turtle,
        },
        {
            path: "/woman",
            name: "Woman",
            meta: {
                title: "La femme tombée du ciel - ",
            },
            component: Woman,
        },
        {
            path: "/circleUniv",
            name: "CircleUniv",
            meta: {
                title: "",
            },
            component: CircleUniv,
        },
        {
            path: "/timeline",
            name: "Timeline",
            meta: {
                title: "",
            },
            component: Timeline,
        },
        {
            path: "/activity",
            name: "Activity",
            meta: {
                title: "Activité - ",
            },
            component: Activity,
        },
        {
            path: "/activityDetail",
            name: "ActivityDetail",
            meta: {
                title: "Activité - ",
            },
            component: ActivityDetail,
        },
        // otherwise redirect to home
        { path: "*", redirect: "/" },
    ],
});

window.popStateDetected = false;
window.addEventListener("popstate", () => {
    window.popStateDetected = true;
});

ROUTER.beforeEach((to, from, next) => {
    const IsItABackButton = window.popStateDetected;
    window.popStateDetected = false;
    if (IsItABackButton && from.meta.someLogica) {
        next(false);
        return "";
    }
    next();
});

ROUTER.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title + DEFAULT_TITLE;
    });
});

export default ROUTER;
