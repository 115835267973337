const state = {
    activeThematic: undefined,
    timelineBtnImgBase: {
        nextImg: require("../../assets/img/nav/nextTheme/nextTheme_off.svg"),
        prevImg: require("../../assets/img/nav/prevTheme/prevTheme_off.svg"),
    },
    equivalIdxId: {},
    thematics: undefined,
    datasForThematics: {
        26: {
            color: "#a93492",
            idPostDescription: 401,
            circleImg: require("../../assets/img/circles/allianceDiplomatieCommerce.svg"),
            //circleImg: require("../../assets/img/circles/allianceDiplomatieCommerce.png"),
            timelineSignImg: require("../../assets/img/nav/+sign/+sign_purple.svg"),
            timelineNextImg: require("../../assets/img/nav/nextTheme/nextTheme_purple.svg"),
            timelinePrevImg: require("../../assets/img/nav/prevTheme/prevTheme_purple.svg"),
        },
        23: {
            color: "#c82123",
            idPostDescription: 395,
            circleImg: require("../../assets/img/circles/calendrierRituel.svg"),
            //circleImg: require("../../assets/img/circles/calendrierRituel.png"),
            timelineSignImg: require("../../assets/img/nav/+sign/+sign_red.svg"),
            timelineNextImg: require("../../assets/img/nav/nextTheme/nextTheme_red.svg"),
            timelinePrevImg: require("../../assets/img/nav/prevTheme/prevTheme_red.svg"),
        },
        24: {
            color: "#00adee",
            idPostDescription: 397,
            circleImg: require("../../assets/img/circles/economieSubsistance.svg"),
            //circleImg: require("../../assets/img/circles/economieSubsistance.png"),
            timelineSignImg: require("../../assets/img/nav/+sign/+sign_blue.svg"),
            timelineNextImg: require("../../assets/img/nav/nextTheme/nextTheme_blue.svg"),
            timelinePrevImg: require("../../assets/img/nav/prevTheme/prevTheme_blue.svg"),
        },
        27: {
            color: "#c6c8ca",
            idPostDescription: 403,
            circleImg: require("../../assets/img/circles/jeuxDivertissements.svg"),
            //circleImg: require("../../assets/img/circles/jeuxDivertissements.png"),
            timelineSignImg: require("../../assets/img/nav/+sign/+sign_grey.svg"),
            timelineNextImg: require("../../assets/img/nav/nextTheme/nextTheme_grey.svg"),
            timelinePrevImg: require("../../assets/img/nav/prevTheme/prevTheme_grey.svg"),
        },
        5: {
            color: "#e5e536",
            idPostDescription: 390,
            circleImg: require("../../assets/img/circles/periodeDeLannee.svg"),
            //circleImg: require("../../assets/img/circles/periodeDeLannee.png"),
            timelineSignImg: require("../../assets/img/nav/+sign/+sign_yellow.svg"),
            timelineNextImg: require("../../assets/img/nav/nextTheme/nextTheme_yellow.svg"),
            timelinePrevImg: require("../../assets/img/nav/prevTheme/prevTheme_yellow.svg"),
        },
        25: {
            color: "#ee7622",
            idPostDescription: 399,
            circleImg: require("../../assets/img/circles/techniquesSavoir.svg"),
            //circleImg: require("../../assets/img/circles/techniquesSavoir.png"),
            timelineSignImg: require("../../assets/img/nav/+sign/+sign_orange.svg"),
            timelineNextImg: require("../../assets/img/nav/nextTheme/nextTheme_orange.svg"),
            timelinePrevImg: require("../../assets/img/nav/prevTheme/prevTheme_orange.svg"),
        },
        6: {
            color: "#9fc93b",
            idPostDescription: 393,
            circleImg: require("../../assets/img/circles/traditionOrale.svg"),
            //circleImg: require("../../assets/img/circles/traditionOrale.png"),
            timelineSignImg: require("../../assets/img/nav/+sign/+sign_green.svg"),
            timelineNextImg: require("../../assets/img/nav/nextTheme/nextTheme_green.svg"),
            timelinePrevImg: require("../../assets/img/nav/prevTheme/prevTheme_green.svg"),
        },
    },
    circleThematicHover: undefined,
    timelineBtnNextIsHover: false,
    timelineBtnPrevIsHover: false,
};

const getters = {
    activeName(state) {
        return state.thematics[state.equivalIdxId[state.activeThematic]].name;
    },
    activeColor(state) {
        return state.datasForThematics[state.activeThematic].color;
    },
    activeSign(state) {
        return state.datasForThematics[state.activeThematic].timelineSignImg;
    },
    next(state) {
        if (state.equivalIdxId[state.activeThematic] - 1 > 0) {
            return state.thematics[
                state.equivalIdxId[state.activeThematic] - 1
            ];
        } else {
            return state.thematics[Object.keys(state.thematics).length];
        }
    },
    nextImgBase(state) {
        return state.timelineBtnImgBase.nextImg;
    },
    nextImg(state) {
        let idx = state.equivalIdxId[state.activeThematic];
        if (idx - 1 > 0) {
            idx = state.equivalIdxId[state.activeThematic] - 1;
        } else {
            idx = Object.keys(state.thematics).length;
        }
        if (state.timelineBtnNextIsHover) {
            return state.datasForThematics[state.thematics[idx].id]
                .timelineNextImg;
        } else {
            return state.timelineBtnImgBase.nextImg;
        }
    },
    prev(state) {
        if (
            state.equivalIdxId[state.activeThematic] + 1 <=
            Object.keys(state.thematics).length
        ) {
            return state.thematics[
                state.equivalIdxId[state.activeThematic] + 1
            ];
        } else {
            return state.thematics[1];
        }
    },
    prevImgBase(state) {
        return state.timelineBtnImgBase.nextImg;
    },
    prevImg(state) {
        let idx = state.equivalIdxId[state.activeThematic];
        if (idx + 1 <= Object.keys(state.thematics).length) {
            idx = state.equivalIdxId[state.activeThematic] + 1;
        } else {
            idx = 1;
        }
        if (state.timelineBtnPrevIsHover) {
            return state.datasForThematics[state.thematics[idx].id]
                .timelinePrevImg;
        } else {
            return state.timelineBtnImgBase.prevImg;
        }
    },
};

const actions = {
    getThematics({ commit, dispatch }) {
        dispatch("ThematicApi/fetchThematics", [], { root: true })
            .then((jsonResponse) => {
                if (jsonResponse.status == 200) {
                    commit("setThematics", jsonResponse.data);
                    dispatch("getThematicsDescriptions");
                }
            })
            .catch((erreur) => {
                console.log("Error getThematics catch : " + erreur);
            });
    },
    async getThematicsDescriptions({ dispatch }) {
        for (const key in state.thematics) {
            await dispatch("getThematicDescription", [
                key,
                state.datasForThematics[state.thematics[key].id]
                    .idPostDescription,
            ]);
        }
    },
    async getThematicDescription({ commit, dispatch }, [idThematic, idPost]) {
        dispatch(
            "ThematicApi/fetchThematicDescription",
            [idPost, "?_fields[]=content"],
            {
                root: true,
            }
        )
            .then((jsonResponse) => {
                if (jsonResponse.status == 200) {
                    jsonResponse.data.idThematic = idThematic;
                    commit("setThematicDescription", jsonResponse.data);
                }
            })
            .catch((erreur) => {
                console.log("Error getThematicDescription catch : " + erreur);
            });
    },
    select({ commit }, activeThematic) {
        commit("setActiveThematic", activeThematic);
    },
    circleHover({ state, commit }, activeThematic) {
        let imgActive = undefined;
        if (activeThematic != undefined) {
            imgActive = state.datasForThematics[activeThematic].circleImg;
        } else if (state.activeThematic != undefined) {
            imgActive = state.datasForThematics[state.activeThematic].circleImg;
        }
        commit("setCircleHover", imgActive);
    },
    timelineNextHover({ commit }, status) {
        commit("setTimelineNextHover", status);
    },
    timelinePrevHover({ commit }, status) {
        commit("setTimelinePrevHover", status);
    },
};

const mutations = {
    setThematicDescription(state, datas) {
        state.thematics[datas.idThematic].description = datas.content.rendered;
    },
    setActiveThematic(state, activeThematic) {
        state.activeThematic = activeThematic;
    },
    setCircleHover(state, imgActiveSeason) {
        state.circleThematicHover = imgActiveSeason;
    },
    setThematics(state, thematics) {
        let order = 0;
        state.thematics = {};
        thematics.forEach((element) => {
            order = parseInt(element.slug.substring(0, 2));

            state.thematics[order] = {};
            state.thematics[order].id = element.id;
            state.thematics[order].name = element.name;
            state.thematics[order].order = order;
            state.equivalIdxId[element.id] = parseInt(order);
        });
    },
    setTimelineNextHover(state, status) {
        state.timelineBtnNextIsHover = status;
    },
    setTimelinePrevHover(state, status) {
        state.timelineBtnPrevIsHover = status;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
