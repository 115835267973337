const state = {
    creationInProgress: false,
    activitys: undefined,
    idActivityActive: undefined,
    rowsFullActivitys: [],
    activityOnlyStart: [],
    activityOnlyCenter: [],
    activityOnlyEnd: [],
    activityTwoStart: [],
    activityTwoSide: [],
    activityTwoEnd: [],
};

const getters = {
    nbRow(state) {
        return state.rowsFullActivitys.length;
    },
    nextActivity(state) {
        // Find next activite in rows
        let useNextActivity = false;
        let firstArrayActivity = undefined;
        for (const idxRow in state.rowsFullActivitys) {
            for (const idxActivity in state.rowsFullActivitys[idxRow]) {
                if (
                    !state.rowsFullActivitys[idxRow][
                        idxActivity
                    ].hasOwnProperty("emptySpace")
                ) {
                    if (firstArrayActivity == undefined) {
                        firstArrayActivity =
                            state.rowsFullActivitys[idxRow][idxActivity];
                    }
                    if (
                        !useNextActivity &&
                        state.rowsFullActivitys[idxRow][idxActivity].id ==
                            state.idActivityActive
                    ) {
                        useNextActivity = true;
                    } else if (useNextActivity) {
                        return state.rowsFullActivitys[idxRow][idxActivity];
                    }
                }
            }
        }
        return firstArrayActivity;
    },
    prevActivity(state) {
        // Transforme pour reverse and find end
        let tmpRowsFullActivitys = JSON.parse(
            JSON.stringify(state.rowsFullActivitys)
        );
        tmpRowsFullActivitys = tmpRowsFullActivitys.reverse();
        let activityRev = undefined;

        // Find next activite in rows
        let useNextActivity = false;
        let firstArrayActivity = undefined;
        for (const idxRow in tmpRowsFullActivitys) {
            activityRev = tmpRowsFullActivitys[idxRow].reverse();
            for (const idxActivity in activityRev) {
                if (!activityRev[idxActivity].hasOwnProperty("emptySpace")) {
                    if (firstArrayActivity == undefined) {
                        firstArrayActivity = activityRev[idxActivity];
                    }
                    if (
                        !useNextActivity &&
                        activityRev[idxActivity].id == state.idActivityActive
                    ) {
                        useNextActivity = true;
                    } else if (useNextActivity) {
                        return activityRev[idxActivity];
                    }
                }
            }
        }
        return firstArrayActivity;
    },
};

const actions = {
    async getActivitysDatasByPeriodes({
        commit,
        dispatch,
        rootState,
        rootGetters,
    }) {
        commit("resetActivitys");

        let idThematic = rootState.Thematics.activeThematic;
        let idPeriode = rootGetters["Seasons/idApiMonthsSeason"];
        // Get 3 mouths + 1 month before and after.
        await dispatch("getApiActivitys", [idThematic, idPeriode[0]]);
        await dispatch("getApiActivitys", [idThematic, idPeriode[1]]);
        await dispatch("getApiActivitys", [idThematic, idPeriode[2]]);
        await dispatch("getApiActivitys", [idThematic, idPeriode[3]]);
        await dispatch("getApiActivitys", [idThematic, idPeriode[4], true]);
    },
    async getApiActivitys({ commit, dispatch }, [idThematic, idPeriode, sort]) {
        await dispatch("ActivityApi/fetchActivitys", [idThematic, idPeriode], {
            root: true,
        })
            .then((jsonResponse) => {
                if (jsonResponse.status == 200) {
                    commit("addActivitys", jsonResponse.data);
                    if (sort) {
                        dispatch("identifyRoundSideAtivityDiv").then(() => {
                            dispatch("sortActivitys");
                        });
                    }
                }
            })
            .catch((erreur) => {
                console.log("Error getApiActivitys catch : " + erreur);
            });
    },
    identifyRoundSideAtivityDiv({ state, rootGetters }) {
        let numMonths = rootGetters["Seasons/idApiMonthsSeason"];

        for (const idActivity in state.activitys) {
            // Detect round start and end for activuty
            if (
                state.activitys[idActivity].periode.includes(numMonths[0]) &&
                state.activitys[idActivity].periode.includes(numMonths[1])
            ) {
                state.activitys[idActivity].roundStart = false;
            }

            if (
                state.activitys[idActivity].periode.includes(numMonths[3]) &&
                state.activitys[idActivity].periode.includes(numMonths[4])
            ) {
                state.activitys[idActivity].roundEnd = false;
            }
        }
    },
    sortActivitys({ dispatch }) {
        dispatch("prepareToSort");
        dispatch("joinTwoStartWithOnlyEnd");
        dispatch("joinTwoSideWithOnlyCenter");
        dispatch("joinTwoEndWithOnlyStart");
        dispatch("joinThreeOnlyOne");
        dispatch("joinTwoOnlyOne");
        dispatch("addOnlyOne");
    },
    prepareToSort({ commit, state, rootGetters }) {
        let numMonths = rootGetters["Seasons/idApiMonthsSeason"];
        let activity = undefined;

        // Put activity in the good array.
        for (const idActivity in state.activitys) {
            activity = state.activitys[idActivity];
            if (
                activity.periode.includes(numMonths[1]) &&
                activity.periode.includes(numMonths[2]) &&
                activity.periode.includes(numMonths[3])
            ) {
                activity.lenActivity = 100;
                commit("addActivitysToRangeFull", [activity]);
            } else if (
                activity.periode.includes(numMonths[1]) &&
                !activity.periode.includes(numMonths[2]) &&
                !activity.periode.includes(numMonths[3])
            ) {
                activity.lenActivity = 33.4;
                commit("addActivityOnlyStart", activity);
            } else if (
                !activity.periode.includes(numMonths[1]) &&
                activity.periode.includes(numMonths[2]) &&
                !activity.periode.includes(numMonths[3])
            ) {
                activity.lenActivity = 33.4;
                commit("addActivityOnlyCenter", activity);
            } else if (
                !activity.periode.includes(numMonths[1]) &&
                !activity.periode.includes(numMonths[2]) &&
                activity.periode.includes(numMonths[3])
            ) {
                activity.lenActivity = 33.4;
                commit("addActivityOnlyEnd", activity);
            } else if (
                activity.periode.includes(numMonths[1]) &&
                activity.periode.includes(numMonths[2]) &&
                !activity.periode.includes(numMonths[3])
            ) {
                activity.lenActivity = 66.6;
                commit("addActivityTwoStart", activity);
            } else if (
                activity.periode.includes(numMonths[1]) &&
                !activity.periode.includes(numMonths[2]) &&
                activity.periode.includes(numMonths[3])
            ) {
                activity.lenActivity = 66.6;
                commit("addActivityTwoSide", activity);
            } else if (
                !activity.periode.includes(numMonths[1]) &&
                activity.periode.includes(numMonths[2]) &&
                activity.periode.includes(numMonths[3])
            ) {
                activity.lenActivity = 66.6;
                commit("addActivityTwoEnd", activity);
            }
        }
    },
    joinTwoStartWithOnlyEnd({ state, commit }) {
        for (let i = 0; i < state.activityTwoStart.length; i++) {
            for (let j = 0; j < state.activityOnlyEnd.length; j++) {
                commit("addActivitysToRangeFull", [
                    state.activityTwoStart[i],
                    state.activityOnlyEnd[j],
                ]);
                commit("removeActivityTwoStart", i);
                commit("removeActivityOnlyEnd", j);
                i -= 1;
                break;
            }
        }
        for (let i = 0; i < state.activityTwoStart.length; i++) {
            commit("addActivitysToRangeFull", [state.activityTwoStart[i]]);
        }
    },
    joinTwoSideWithOnlyCenter({ state, commit }) {
        for (let i = 0; i < state.activityTwoSide.length; i++) {
            for (let j = 0; j < state.activityOnlyCenter.length; j++) {
                commit("addActivitysToRangeFull", [
                    state.activityTwoSide[i],
                    state.activityOnlyCenter[j],
                ]);
                commit("removeActivityTwoSide", i);
                commit("removeActivityOnlyCenter", j);
                i -= 1;
                break;
            }
        }
        for (let i = 0; i < state.activityTwoSide.length; i++) {
            commit("addActivitysToRangeFull", [
                state.activityTwoSide[i],
                { emptySpace: true },
                state.activityTwoSide[i],
            ]);
        }
    },
    joinTwoEndWithOnlyStart({ state, commit }) {
        for (let i = 0; i < state.activityTwoEnd.length; i++) {
            for (let j = 0; j < state.activityOnlyStart.length; j++) {
                commit("addActivitysToRangeFull", [
                    state.activityOnlyStart[j],
                    state.activityTwoEnd[i],
                ]);
                commit("removeActivityTwoEnd", i);
                commit("removeActivityOnlyStart", j);
                i -= 1;
                break;
            }
        }
        for (let i = 0; i < state.activityTwoEnd.length; i++) {
            commit("addActivitysToRangeFull", [
                { emptySpace: false },
                state.activityTwoEnd[i],
            ]);
        }
    },
    joinThreeOnlyOne({ state, commit }) {
        for (let i = 0; i < state.activityOnlyStart.length; i++) {
            for (let j = 0; j < state.activityOnlyCenter.length; j++) {
                for (let k = 0; k < state.activityOnlyEnd.length; k++) {
                    commit("addActivitysToRangeFull", [
                        state.activityOnlyStart[i],
                        state.activityOnlyCenter[j],
                        state.activityOnlyEnd[k],
                    ]);
                    commit("removeActivityOnlyStart", i);
                    commit("removeActivityOnlyCenter", j);
                    commit("removeActivityOnlyEnd", k);
                    i -= 1;
                    j -= 1;
                    break;
                }
            }
        }
    },
    joinTwoOnlyOne({ state, commit }) {
        for (let i = 0; i < state.activityOnlyStart.length; i++) {
            for (let j = 0; j < state.activityOnlyCenter.length; j++) {
                commit("addActivitysToRangeFull", [
                    state.activityOnlyStart[i],
                    state.activityOnlyCenter[j],
                ]);
                commit("removeActivityOnlyStart", i);
                commit("removeActivityOnlyCenter", j);
                i -= 1;
                break;
            }
        }

        for (let i = 0; i < state.activityOnlyStart.length; i++) {
            for (let j = 0; j < state.activityOnlyEnd.length; j++) {
                commit("addActivitysToRangeFull", [
                    state.activityOnlyStart[i],
                    { emptySpace: true },
                    state.activityOnlyEnd[j],
                ]);
                commit("removeActivityOnlyStart", i);
                commit("removeActivityOnlyEnd", j);
                i -= 1;
                break;
            }
        }

        for (let i = 0; i < state.activityOnlyCenter.length; i++) {
            for (let j = 0; j < state.activityOnlyEnd.length; j++) {
                commit("addActivitysToRangeFull", [
                    { emptySpace: true },
                    state.activityOnlyCenter[i],
                    state.activityOnlyEnd[j],
                ]);
                commit("removeActivityOnlyCenter", i);
                commit("removeActivityOnlyEnd", j);
                i -= 1;
                break;
            }
        }
    },
    addOnlyOne({ state, commit }) {
        for (const key in state.activityOnlyStart) {
            commit("addActivitysToRangeFull", [state.activityOnlyStart[key]]);
        }
        for (const key in state.activityOnlyCenter) {
            commit("addActivitysToRangeFull", [
                { emptySpace: true },
                state.activityOnlyCenter[key],
            ]);
        }
        for (const key in state.activityOnlyEnd) {
            commit("addActivitysToRangeFull", [
                { emptySpace: true },
                { emptySpace: true },
                state.activityOnlyEnd[key],
            ]);
        }
    },
};

const mutations = {
    setCreationInProgress(state, status) {
        state.creationInProgress = status;
    },
    setActiveActivity(state, idActivity) {
        state.idActivityActive = idActivity;
    },
    resetActivitys(state) {
        state.activitys = {};
        state.rowsFullActivitys = [];
        state.activityOnlyStart = [];
        state.activityOnlyCenter = [];
        state.activityOnlyEnd = [];
        state.activityTwoStart = [];
        state.activityTwoSide = [];
        state.activityTwoEnd = [];
    },
    addActivitys(state, activitys) {
        activitys.forEach((activity) => {
            if (!state.activitys.hasOwnProperty(activity.id)) {
                state.activitys[activity.id] = {};
                state.activitys[activity.id].id = activity.id;
                state.activitys[activity.id].title = activity.title.rendered;
                state.activitys[activity.id].periode = activity.periode;
                state.activitys[activity.id].media = activity.featured_media;
                state.activitys[activity.id].roundStart = true;
                state.activitys[activity.id].roundEnd = true;
            }
        });
    },
    addActivitysToRangeFull(state, activitys) {
        state.rowsFullActivitys.push(activitys);
    },
    addActivityOnlyStart(state, activitys) {
        state.activityOnlyStart.push(activitys);
    },
    removeActivityOnlyStart(state, key) {
        state.activityOnlyStart.splice(key, 1);
    },
    addActivityOnlyCenter(state, activitys) {
        state.activityOnlyCenter.push(activitys);
    },
    removeActivityOnlyCenter(state, key) {
        state.activityOnlyCenter.splice(key, 1);
    },
    addActivityOnlyEnd(state, activitys) {
        state.activityOnlyEnd.push(activitys);
    },
    removeActivityOnlyEnd(state, key) {
        state.activityOnlyEnd.splice(key, 1);
    },
    addActivityTwoStart(state, activitys) {
        state.activityTwoStart.push(activitys);
    },
    removeActivityTwoStart(state, key) {
        state.activityTwoStart.splice(key, 1);
    },
    addActivityTwoSide(state, activitys) {
        state.activityTwoSide.push(activitys);
    },
    removeActivityTwoSide(state, key) {
        state.activityTwoSide.splice(key, 1);
    },
    addActivityTwoEnd(state, activitys) {
        state.activityTwoEnd.push(activitys);
    },
    removeActivityTwoEnd(state, key) {
        state.activityTwoEnd.splice(key, 1);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
