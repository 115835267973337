import { render, staticRenderFns } from "./CircleUnivCircle.vue?vue&type=template&id=16d73e4e&scoped=true&"
import script from "./CircleUnivCircle.vue?vue&type=script&lang=js&"
export * from "./CircleUnivCircle.vue?vue&type=script&lang=js&"
import style0 from "./CircleUnivCircle.vue?vue&type=style&index=0&id=16d73e4e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16d73e4e",
  null
  
)

export default component.exports