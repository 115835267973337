<template>
    <div>
        <div
            class="seasonDiv"
            :style="{
                top: this.seasons[this.idSeason].top,
                left: this.seasons[this.idSeason].left,
                width: this.seasons[this.idSeason].width,
                height: this.seasons[this.idSeason].height,
            }"
            @click.capture="seasonClick(idSeason)"
            @mouseenter.capture="hoverSeason(idSeason)"
            @mouseout.capture="outSeason()"
        ></div>
    </div>
</template>

<style>
.seasonDiv {
    position: absolute;
    cursor: pointer;
    /*border: 1px solid blue;*/
    z-index: 99;
}
.seasonDiv:hover {
    position: absolute;
}
</style>

<script>
import { mapState } from "vuex";
import store from "../store";

export default {
    name: "Season",

    data() {
        return {
            hoverActive: false,
        };
    },
    props: {
        idSeason: String,
    },

    computed: {
        ...mapState({
            seasons: (state) => state.CircleUniv.seasons,
        }),
    },

    methods: {
        seasonClick(idSeason) {
            store.dispatch("Seasons/select", idSeason);
        },
        hoverSeason(idSeason) {
            this.hoverActive = true;
            store.dispatch("CircleUniv/changeHover", idSeason);
            let that = this;
            setTimeout(function () {
                that.hoverActive = false;
            }, 10);
        },
        outSeason() {
            if (!this.hoverActive) {
                store.dispatch("CircleUniv/changeHover", undefined);
            }
        },
    },
};
</script>
