const state = {
    baseFields: "?_fields[]=title&_fields[]=content",
};

const getters = {};

const actions = {
    fetchContent({ state, dispatch }) {
        return dispatch("PostApi/fetchPost", [458, state.baseFields], {
            root: true,
        });
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
