<template>
    <div class="windowSize" id="refContainerPrincipal">
        <div id="refBorderExterne">
            <div id="refBorderInterne">
                <div id="refContainerTextImg">
                    <div id="refContainerText">
                        <div id="refBook">
                            <v-img
                                id="refBookImg"
                                width="10vmin"
                                contain
                                :src="require('../assets/img/bookIcon.svg')"
                            ></v-img>
                        </div>
                        <span id="refTitle" v-html="this.title"></span>
                        <v-skeleton-loader
                            v-if="content == ''"
                            type="article"
                            class="refText"
                        ></v-skeleton-loader>
                        <p class="refText" v-html="this.content"></p>
                    </div>
                    <div id="turlteContainterImg">
                        <v-img
                            id="turlteImg"
                            width="75%"
                            contain
                            :src="require('../assets/img/oldTable.png')"
                            @click.stop="dialogCall = true"
                        ></v-img>
                        <dialogModal
                            widthDialog="90%"
                            cssName="dialogRef"
                            title="Référence imprimé"
                            mode="img"
                            :contents="{
                                src: require('../assets/img/oldTable.png'),
                            }"
                            footer="Tous droits réservés. © Secteur Culture et patrimoine, CDFM, Nation huronne-wendat. La publication, la reproduction et la distribution en totalité ou en partie sont strictement interdites à moins d’avoir préalablement obtenu l’autorisation écrite du propriétaire du site."
                            :dialogOpen="dialogCall"
                            @dialogClose="dialogCall = false"
                        />
                    </div>
                </div>
                <div id="refBtnsEnters" @click="$router.push('/Turtle')">
                    <div id="refBtnsEntersText">
                        <span id="refBtnEnterFr">COMMENCER</span>
                    </div>
                    <v-img
                        id="refImgRightArrow"
                        contain
                        :src="require('../assets/img/nav/arrow/rightArrow.svg')"
                        width="10vmin"
                        height="5vmin"
                    >
                    </v-img>
                </div>
            </div>
        </div>
        <appFooter :mode="1"></appFooter>
    </div>
</template>

<style>
#refContainerPrincipal {
    margin: 10px auto;
}
#refBorderExterne {
    margin: 0 auto;
    padding: 4px;
    border: #e7d7c2 solid 2.5px;
    width: 85%;
    height: 79%;
}
#refBorderInterne {
    border: #e7d7c2 solid 5.5px;
    height: 100%;
}
#refContainerTextImg {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 100%;
}
#refContainerText {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 50%;
}
#refBook {
}
#refBookImg {
}
#refTitle {
    margin: 15px;
    font-family: "Cinzel-Black";
    color: #a78a7f;
    text-transform: uppercase;
    font-size: 2.5vmin;
}
.refText {
    text-align: center;
    font-family: "SourceSerifPro-Regular";
    color: #745852;
    font-size: 2vmin;
    width: 75%;
}
#turlteContainterImg {
    display: flex;
    flex-flow: column;
    width: 45%;
}
#turlteImg {
    cursor: pointer;
    transition-duration: 500ms;
}
#turlteImg:hover {
    opacity: 0.8;
}
#refBtnsEnters {
    display: flex;
    flex-flow: row;
    align-items: center;
    position: absolute;
    top: 40%;
    right: 2%;
    cursor: pointer;
    height: 10%;
    background-color: white;
}
#refBtnEnterFr {
    font-family: "Montserrat-Black";
    color: #a78a7f;
    text-transform: uppercase;
    font-size: 1.5vmin;
    letter-spacing: 1.5px;
}
#refImgRightArrow {
    position: relative;
    left: 5px;
}
#dialogRefTitle {
    margin: 20px;
    font-family: "Cinzel-Black";
    color: #a78a7f;
    text-transform: uppercase;
    font-size: 4vmin;
}
#dialogRefImg {
    width: 150vw;
    height: 150vh;
}
#dialogRefFooter {
    text-align: center;
    font-family: "SourceSerifPro-Regular";
    color: #745852;
    font-size: 2vmin;
}
</style>

<script>
import { mapState } from "vuex";
import store from "../store";
import appFooter from "./appFooter";
import dialogModal from "./DialogModal";

export default {
    name: "Reference",

    data() {
        return {
            dialogCall: false,
        };
    },

    components: {
        appFooter: appFooter,
        dialogModal: dialogModal,
    },

    mounted() {
        store.dispatch("Reference/getContent");
    },

    computed: {
        ...mapState({
            title: (state) => state.Reference.title,
            content: (state) => state.Reference.content,
        }),
    },
};
</script>
