<template>
    <v-footer id="footerContainer" fixed width="auto">
        <div id="footerNavContainer">
            <div
                id="footerBtnLeft"
                v-if="mode == 2"
                @click="seasonClick(prevSeason.id)"
            >
                <v-img
                    id="footerImgLeftArrow"
                    contain
                    :src="require('../assets/img/nav/arrow/leftArrow_pink.svg')"
                    width="5vmin"
                    height="2vmin"
                >
                </v-img>
                <div id="footerBtnLeftText">
                    <span
                        class="footerBtnEnter"
                        v-html="this.prevSeason.wendatName"
                    ></span>
                    <span class="footerBtnEnterFr">-</span>
                    <span
                        class="footerBtnEnterFr"
                        v-html="this.prevSeason.frName"
                    ></span>
                </div>
            </div>
            <div
                id="footerBtnLeft"
                v-if="mode == 3"
                @click="activityClick(prevActivity.id)"
            >
                <v-img
                    id="footerImgLeftArrow"
                    contain
                    :src="require('../assets/img/nav/arrow/leftArrow.svg')"
                    width="5vmin"
                    height="2vmin"
                >
                </v-img>
                <div id="footerBtnLeftText">
                    <span class="footerBtnEnterFr">activité précédente</span>
                </div>
            </div>
            <div
                v-if="mode == 1 || mode == 2"
                class="footerContainerTextImg"
                @click="$router.push('/circleUniv')"
            >
                <div class="footerText" v-if="mode == 1">
                    découvrer l'univers wendat
                </div>
                <div class="footerText" v-if="mode == 2">retour au début</div>
                <v-img
                    id="footerImgCircle"
                    transition="slide-y-transition"
                    :src="require('../assets/img/nav/circles.png')"
                    width="23vmin"
                    height="7vmin"
                    position="bottom"
                ></v-img>
            </div>
            <div
                class="footerContainerTextImg"
                v-if="mode == 3"
                @click="$router.push('/Timeline')"
            >
                <div class="footerText">retour vers la thématique</div>
                <v-img
                    id="footerImgDownArrow"
                    contain
                    :src="require('../assets/img/nav/arrow/downArrow.svg')"
                    width="3vmin"
                    height="6vmin"
                ></v-img>
            </div>
            <div
                id="footerBtnRight"
                v-if="mode == 2"
                @click="seasonClick(nextSeason.id)"
            >
                <div id="footerBtnRightText">
                    <span
                        class="footerBtnEnterFr"
                        v-html="this.nextSeason.frName"
                    ></span>
                    <span class="footerBtnEnterFr">-</span>
                    <span
                        class="footerBtnEnter"
                        v-html="this.nextSeason.wendatName"
                    ></span>
                </div>
                <v-img
                    id="footerImgLeftArrow"
                    contain
                    :src="
                        require('../assets/img/nav/arrow/rightArrow_pink.svg')
                    "
                    width="5vmin"
                    height="2vmin"
                ></v-img>
            </div>
            <div
                id="footerBtnRight"
                v-if="mode == 3"
                @click="activityClick(nextActivity.id)"
            >
                <div id="footerBtnRightText">
                    <span class="footerBtnEnterFr">activité suivante</span>
                </div>
                <v-img
                    id="footerImgLeftArrow"
                    contain
                    :src="require('../assets/img/nav/arrow/rightArrow.svg')"
                    width="5vmin"
                    height="2vmin"
                ></v-img>
            </div>
        </div>
        <div id="footerCredit">
            <div id="footerLeftCredit">
                © CDFM - Culture et Patrimoine, 2021
            </div>
            <dialogModal
                widthDialog="90%"
                cssName="dialogFooter"
                :title="this.title"
                :contents="[{ description: this.content }]"
                :dialogOpen="dialogCall"
                @dialogClose="dialogCall = false"
            />
            <div id="footerRightCredit" @click.stop="dialogCall = true">
                Crédits
            </div>
        </div>
    </v-footer>
</template>

<style>
#footerContainer {
    display: flex;
    flex-flow: column;
    padding: 0px;
    margin: 0px;
}
#footerNavContainer {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    background-image: url("../assets/img/patternBottom.svg");
    background-repeat: repeat;
    width: 100%;
    height: 13%;
}
#footerBtnLeft {
    display: flex;
    flex-flow: row;
    align-items: center;
    cursor: pointer;
    width: 38%;
}
#footerImgLeftArrow {
    height: 15px;
}
#footerBtnLeftText {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
}
.footerBtnEnter {
    font-family: "Vollkorn-SemiBoldItalic";
    font-size: 4vmin;
    text-transform: capitalize;
    color: #ee3c84;
}
.footerBtnEnterFr {
    font-family: "Montserrat-Black";
    color: #745852;
    text-transform: uppercase;
    margin: 5px;
    letter-spacing: 2px;
    font-size: 2vmin;
}
.footerContainerTextImg {
    display: flex;
    flex-flow: column;
    align-items: center;
    cursor: pointer;
    padding-top: 5px;
    width: 30%;
}
.footerText {
    text-align: center;
    font-family: "Montserrat-Black";
    color: #745852;
    text-transform: uppercase;
    font-size: 2vmin;
    position: relative;
}
#footerImgCircle {
    background-position: bottom;
}
#footerBtnRight {
    display: flex;
    flex-flow: row;
    align-items: center;
    cursor: pointer;
    width: 38%;
}
#footerBtnRightText {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 80%;
}
#footerImgRightArrow {
}
#footerCredit {
    display: flex;
    flex-flow: row;
    color: #745852;
    background-color: rgb(231, 215, 194, 0.3);
    padding: 0px 10px 0px 5px;
    width: 100%;
}
#footerLeftCredit {
    text-align: center;
    font-family: "Montserrat-Light";
    color: #745852;
    font-size: 1.7vmin;
    display: flex;
    justify-content: flex-start;
    width: 50%;
}
#footerRightCredit {
    text-align: center;
    font-family: "Montserrat-Light";
    color: #745852;
    font-size: 1.7vmin;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    width: 50%;
}
#dialogFooterTitle {
    margin: 20px;
    font-family: "Cinzel-Black";
    color: #a78a7f;
    text-transform: uppercase;
    font-size: 4vmin;
}
#dialogFooterSubTitle {
    font-family: "Montserrat-Light";
    color: #745852;
    margin: 20px;
}
#dialogFooterText {
    font-family: "SourceSerifPro-Regular";
    color: #745852;
    width: 75%;
    margin: 20px;
}
</style>

<script>
import { mapState, mapGetters } from "vuex";
import store from "../store";
import dialogModal from "./DialogModal";

export default {
    name: "appFooter",

    props: {
        mode: Number,
    },

    data() {
        return {
            dialogCall: false,
        };
    },

    components: {
        dialogModal: dialogModal,
    },

    mounted() {
        store.dispatch("Credits/getContent");
    },

    computed: {
        ...mapState({
            idActivityActive: (state) => state.Activity.id,
            creationInProgress: (state) =>
                state.TimelineActivitys.creationInProgress,
            title: (state) => state.Credits.title,
            content: (state) => state.Credits.content,
        }),
        ...mapGetters({
            nextSeason: "Seasons/next",
            prevSeason: "Seasons/prev",
            nextActivity: "TimelineActivitys/nextActivity",
            prevActivity: "TimelineActivitys/prevActivity",
        }),
    },
    methods: {
        async seasonClick(idSeason) {
            if (!this.creationInProgress) {
                store.commit("TimelineActivitys/setCreationInProgress", true);
                store.dispatch("Seasons/select", idSeason);
                await store.dispatch(
                    "TimelineActivitys/getActivitysDatasByPeriodes",
                    {
                        root: true,
                    }
                );
                store.commit("TimelineActivitys/setCreationInProgress", false);
            }
        },
        activityClick(id) {
            store.commit("TimelineActivitys/setActiveActivity", id);
            store.dispatch("Activity/select", id).then(() => {
                store.dispatch("Activity/getApiActivity", {
                    root: true,
                });
            });
        },
    },
};
</script>
