const state = {
    title: "",
    content: "",
};

const getters = {};

const actions = {
    getContent({ commit, dispatch }) {
        dispatch("CreditsApi/fetchContent", [], { root: true })
            .then((jsonResponse) => {
                if (jsonResponse.status == 200) {
                    commit("setContent", jsonResponse.data);
                }
            })
            .catch((erreur) => {
                console.log("Error getContent catch : " + erreur);
            });
    },
};

const mutations = {
    setContent(state, content) {
        state.title = content.title.rendered;
        state.content = content.content.rendered;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
