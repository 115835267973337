<template>
    <div class="windowSize" id="timelinePrincipal">
        <div
            id="timelineNextThematic"
            @click="thematicClick(nextThematic.id)"
            @mouseover="nextHover()"
            @mouseout="nextOut()"
            :style="{
                'background-image': 'url(' + this.nextThematicImg + ')',
            }"
            v-html="this.nextThematic.name"
        ></div>

        <div
            id="timelineThematicTitle"
            :style="{
                'background-color': this.activeThematicColor,
            }"
        >
            <v-img
                id="timelineThematicTitleLeftSide"
                position="left center"
                contain
                :src="require('../assets/img/sideTitle.svg')"
                height="4vw"
            />
            <div
                id="timelineThematicTitleText"
                v-html="this.activeThematicName"
            ></div>
            <v-img
                id="timelineThematicTitleRightSide"
                position="left center"
                contain
                :src="require('../assets/img/sideTitle.svg')"
                height="4vw"
            />
        </div>

        <div id="timelineSeason" v-html="this.activeSeasonDatas.frName"></div>

        <timelineMonths />

        <div
            id="timelineSolsticeContainer"
            v-if="this.activeSeasonDatas.solsticeName != ''"
        >
            <div
                id="timelineSolsticeCircle"
                v-html="this.activeSeasonDatas.solsticeName"
            ></div>
            <div id="timelineSolsticeLine"></div>
        </div>

        <div
            id="timelinePrevThematic"
            @click="thematicClick(prevThematic.id)"
            @mouseover="prevHover()"
            @mouseout="prevOut()"
            :style="{
                'background-image': 'url(' + this.prevThematicImg + ')',
            }"
            v-html="this.prevThematic.name"
        ></div>

        <appFooter :mode="2"></appFooter>
    </div>
</template>

<style scoped>
#timelinePrincipal {
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 91vh;
}
#timelineNextThematic {
    background-position: center;
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat-Black";
    color: #ffffff;
    font-size: 1.5vmin;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    width: 100vw;
    height: 6.5vh;
}
#timelineThematicTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 14%;
}
#timelineThematicTitleLeftSide {
    display: flex;
    align-items: right;
    justify-content: right;
    margin-right: 15px;

    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}
#timelineThematicTitleText {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Cinzel-Black";
    color: #ffffff;
    font-size: 3.8vmin;
    letter-spacing: 2px;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
    height: 14%;
}
#timelineThematicTitleRightSide {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
}
#timelineSeason {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat-SemiBold";
    color: #ffffff;
    font-size: 3vmin;
    letter-spacing: 6px;
    text-transform: uppercase;
    text-align: center;
    background-color: #745852;
    width: 100%;
    height: 5%;
}
#timelineSolsticeContainer {
    z-index: 0;
    position: absolute;
    top: 16%;
    left: 88%;
    width: 8vw;
    height: 54%;
}
#timelineSolsticeCircle {
    font-family: "Montserrat-SemiBold";
    color: #ffffff;
    font-size: 2.2vmin;
    text-transform: uppercase;
    background-color: #745852;
    border-radius: 50%;
    text-align: center;
    padding-top: 30%;
    width: 7.5vw;
    height: 7.5vw;
}
#timelineSolsticeLine {
    color: #ffffff;
    border-right: 3px dashed #745852;
    opacity: 0.6;
    width: 50%;
    height: 100%;
}
#timelinePrevThematic {
    background-size: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat-Black";
    color: #ffffff;
    font-size: 1.5vmin;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    width: 100vw;
    height: 6.5vh;
}
</style>

<script>
import { mapState, mapGetters } from "vuex";
import store from "../store";
import appFooter from "./appFooter";
import TimelineMonths from "./TimelineMonths";

export default {
    name: "Timeline",

    components: {
        appFooter: appFooter,
        timelineMonths: TimelineMonths,
    },

    mounted() {
        store.dispatch("Periodes/getPeriodes", { root: true }).then(() => {
            store
                .dispatch("Seasons/createPeriodeMonthIdApi", [], {
                    root: true,
                })
                .then(() => {
                    store.dispatch(
                        "TimelineActivitys/getActivitysDatasByPeriodes",
                        {
                            root: true,
                        }
                    );
                });
        });
    },

    computed: {
        ...mapState({
            creationInProgress: (state) =>
                state.TimelineActivitys.creationInProgress,
        }),
        ...mapGetters({
            activeThematicName: "Thematics/activeName",
            activeThematicColor: "Thematics/activeColor",
            nextThematicImg: "Thematics/nextImg",
            prevThematicImg: "Thematics/prevImg",
            nextThematic: "Thematics/next",
            prevThematic: "Thematics/prev",
            activeSeasonDatas: "Seasons/activeSeason",
        }),
    },

    methods: {
        async thematicClick(idThematic) {
            if (!this.creationInProgress) {
                store.commit("TimelineActivitys/setCreationInProgress", true);
                store.dispatch("Thematics/select", idThematic);
                await store.dispatch(
                    "TimelineActivitys/getActivitysDatasByPeriodes",
                    {
                        root: true,
                    }
                );

                store.commit("TimelineActivitys/setCreationInProgress", false);
            }
        },
        nextHover(idThematic) {
            store.dispatch("Thematics/timelineNextHover", true);
        },
        nextOut() {
            store.dispatch("Thematics/timelineNextHover", false);
        },
        prevHover(idThematic) {
            store.dispatch("Thematics/timelinePrevHover", true);
        },
        prevOut() {
            store.dispatch("Thematics/timelinePrevHover", false);
        },
    },
};
</script>
