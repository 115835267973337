<template>
    <div class="windowSize" id="timelineMonthsPrincipal">
        <div id="timelineMonths1">
            <div
                class="timelineMonthsTitle"
                v-html="this.activeSeason.monthsName[0]"
            ></div>
        </div>
        <div id="timelineMonths2">
            <div
                class="timelineMonthsTitle"
                v-html="this.activeSeason.monthsName[1]"
            ></div>
        </div>
        <div id="timelineMonths3">
            <div
                class="timelineMonthsTitle"
                v-html="this.activeSeason.monthsName[2]"
            ></div>
        </div>

        <timelineActivitys />
    </div>
</template>

<style scoped>
#timelineMonthsPrincipal {
    display: flex;
    flex-flow: row;
    align-items: center;
    width: 99%;
    height: 63%;
}
#timelineMonths1 {
    width: 33.5%;
    height: 100%;
}
#timelineMonths2 {
    border-right: 1px solid #745852;
    border-left: 1px solid #745852;
    width: 33.7%;
    height: 100%;
}
#timelineMonths3 {
    width: 33.33%;
    height: 100%;
}
.timelineMonthsTitle {
    font-family: "Montserrat-Medium";
    color: #745852;
    font-size: 2vmin;
    text-transform: uppercase;
    letter-spacing: 5px;
    text-align: center;
    margin-top: 10px;
    width: 100%;
}
</style>

<script>
import { mapState, mapGetters } from "vuex";
import TimelineActivitys from "./TimelineActivitys";

export default {
    name: "Timeline",

    components: {
        timelineActivitys: TimelineActivitys,
    },

    computed: {
        ...mapState({
            activitys: (state) => state.TimelineActivitys.activitys,
        }),
        ...mapGetters({
            activeSeason: "Seasons/activeSeason",
        }),
    },
};
</script>
