const state = {
    idActiveActivity: undefined,
    id: undefined,
    title: undefined,
    excerpt: undefined,
    content: undefined,
    periode: undefined,
    periodesNames: undefined,
    images: undefined,
    urlsImages: [],
};

const getters = {
    urlImage1(state) {
        if (state.urlsImages.length > 0) {
            return state.urlsImages[0];
        }
        return false;
    },
    urlImage2(state) {
        if (state.urlsImages.length > 1) {
            return state.urlsImages[1];
        }
        return false;
    },
    urlImage3(state) {
        if (state.urlsImages.length > 2) {
            return state.urlsImages[2];
        }
        return false;
    },
};

const actions = {
    select({ commit }, id) {
        commit("setIdActiveActivity", id);
    },
    getApiActivity({ state, commit, dispatch }) {
        dispatch("ActivityApi/fetchActivity", [state.idActiveActivity], {
            root: true,
        })
            .then((jsonResponse) => {
                if (jsonResponse.status == 200) {
                    commit("setActiveActivity", jsonResponse.data);
                    commit("resetUrlsImages");
                    if (jsonResponse.data.acf.images != null) {
                        dispatch(
                            "getApiImages",
                            jsonResponse.data.acf.images.split(",")
                        );
                    }
                    dispatch("getPeriodesNames");
                }
            })
            .catch((erreur) => {
                console.log("Error getApiActivity catch : " + erreur);
            });
    },
    getApiImages({ commit, dispatch }, idsImages) {
        commit("resetUrlsImages");

        for (const idImage in idsImages) {
            dispatch("MediaApi/fetchMedia", [idsImages[idImage]], {
                root: true,
            })
                .then((jsonResponse) => {
                    if (jsonResponse.status == 200) {
                        commit(
                            "addUrlsImages",
                            jsonResponse.data.media_details.sizes.medium
                                .source_url
                        );
                    }
                })
                .catch((erreur) => {
                    console.log("Error getApiImages catch : " + erreur);
                });
        }
    },
    getPeriodesNames({ dispatch, commit }) {
        dispatch("Periodes/periodesNamesToStr", state.periode, {
            root: true,
        }).then((arrPeriodesNames) => {
            commit("setPeriodesNames", arrPeriodesNames);
        });
    },
};

const mutations = {
    setIdActiveActivity(state, id) {
        state.idActiveActivity = id;
    },
    setActiveActivity(state, activity) {
        state.id = activity.id;
        state.title = activity.title.rendered;
        state.excerpt = activity.excerpt.rendered;
        state.content = activity.content.rendered;
        state.periode = activity.periode;
        state.images = activity.acf.images;
    },
    setPeriodesNames(state, periodesNames) {
        state.periodesNames = periodesNames;
    },
    resetUrlsImages(state) {
        state.urlsImages = [];
    },
    addUrlsImages(state, urlsImages) {
        state.urlsImages.push(urlsImages);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
