const state = {
    periodes: {},
};

const getters = {};

const actions = {
    getPeriodes({ commit, dispatch }) {
        return dispatch("PeriodeApi/fetchPeriodes", [], { root: true })
            .then((jsonResponse) => {
                if (jsonResponse.status == 200) {
                    commit("setPeriodes", jsonResponse.data);
                }
            })
            .catch((erreur) => {
                console.log("Error getPeriodes catch : " + erreur);
            });
    },
    periodesNames({ state }, arrIdPeriodes) {
        let arrBack = [];
        for (const i in arrIdPeriodes) {
            for (const j in state.periodes) {
                if (arrIdPeriodes[i] == state.periodes[j].id) {
                    arrBack.push(state.periodes[j].name);
                }
            }
        }
        return arrBack;
    },
    periodesNamesToStr({ state }, arrIdPeriodes) {
        let namesStr = "";

        let lastName = "";
        let lastOrder = -1;

        for (const i in arrIdPeriodes) {
            for (const j in state.periodes) {
                if (arrIdPeriodes[i] == state.periodes[j].id) {
                    if (namesStr == "") {
                        namesStr = state.periodes[j].name;
                    } else if (lastOrder + 1 == state.periodes[j].order) {
                        lastName = " à " + state.periodes[j].name;
                    } else {
                        if (lastName != "") {
                            namesStr += lastName;
                            lastName = "";
                        }
                        namesStr += ", " + state.periodes[j].name;
                    }
                    lastOrder = state.periodes[j].order;
                    break;
                }
            }
        }
        if (lastName != "") {
            namesStr += lastName;
        }
        return namesStr;
    },
};

const mutations = {
    setPeriodes(state, periodes) {
        let order = 0;
        periodes.forEach((element) => {
            order = parseInt(element.name.substring(0, 2));

            state.periodes[order] = {};
            state.periodes[order].id = element.id;
            state.periodes[order].name = element.slug;
            state.periodes[order].order = order;
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
