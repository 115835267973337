<template>
    <div class="windowSize" id="circleUnivContainerPrincipal">
        <div id="circleUnivMenu">
            <div id="circleUnivMenuContent">
                <span id="circleUnivMenuTitle">Wendat</span>
                <span id="circleUnivMenuSubTitle1">ïonyionhwentsou'tenh</span>
                <span id="circleUnivMenuSubTitle2">L’UNIVERS WENDAT</span>
                <circleUnivMenu />
            </div>
        </div>
        <div id="circleUniv">
            <div
                id="circleUnivBackImg"
                :style="{
                    'background-image':
                        'url(' + require('../assets/img/map.png') + ')',
                }"
            >
                <div class="circleUnivDiv">
                    <v-img
                        id="circleUnivDivImgTop"
                        contain
                        :src="require('../assets/img/divCircle.svg')"
                    />
                    <v-img
                        id="circleUnivDivImgBottom"
                        contain
                        :src="require('../assets/img/divCircle.svg')"
                    />
                </div>
                 <circleUnivCircle id="circleUnivCircle" />
            </div>
        </div>
        <appFooter></appFooter>
    </div>
</template>

<style scoped>
#circleUnivContainerPrincipal {
    display: flex;
    flex-flow: row;
    align-items: center;
}
#circleUnivMenu {
    width: 45vw;
    height: 80%;
}
#circleUnivMenuContent {
    display: flex;
    flex-flow: column;
    position: relative;
    left: 15%;
}
#circleUnivMenuTitle {
    font-family: "Cinzel-Black";
    color: #c82123;
    font-size: 9vmin;
    height: 7%;
}
#circleUnivMenuSubTitle1 {
    font-family: "Vollkorn-SemiBoldItalic";
    color: #c82123;
    font-size: 6vmin;
    height: 5%;
}
#circleUnivMenuSubTitle2 {
    font-family: "Montserrat-Light";
    color: #a78a7f;
    font-size: 3vmin;
    letter-spacing: 8px;
    height: 5%;
}
#circleUniv {
    width: 55vw;
    height: 100%;
}
#circleUnivBackImg {
    display: flex;
    background-size: cover;
    width: 100%;
    height: 100%;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
.circleUnivDiv {
    position: absolute;
    top: -10vmin;
    left: 43.5vw;
    width: 60%;
    display: flex;
    transform-origin: 0 100%;
    position: absolute;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

#circleUnivDivImgTop {
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}
#circleUnivDivImgBottom {
}
#circleUnivCircle {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100vmin;
    height: 100vmin;
}
</style>

<script>
import CircleUnivCircle from "./CircleUnivCircle";
import CircleUnivMenu from "./CircleUnivMenu";
import appFooter from "./appFooter";

export default {
    name: "CircleUniv",

    components: {
        circleUnivCircle: CircleUnivCircle,
        circleUnivMenu: CircleUnivMenu,
        appFooter: appFooter,
    },
};
</script>
