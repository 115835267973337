const state = {
    content: "",
    dialogInfoCompl: "",
    dialogSugg: "",
    dialogMaterial: "",
};

const getters = {};

const actions = {
    getTurtleDatas({ dispatch }) {
        dispatch("getContent");
        dispatch("getDialog");
    },
    getContent({ commit, dispatch }) {
        dispatch("TurtleApi/fetchContent", [], { root: true })
            .then((jsonResponse) => {
                if (jsonResponse.status == 200) {
                    commit("setContent", jsonResponse.data);
                }
            })
            .catch((erreur) => {
                console.log("Error getContent catch : " + erreur);
            });
    },
    getDialog({ commit, dispatch }) {
        dispatch("TurtleApi/fetchDialogInfoCompl", [], { root: true })
            .then((jsonResponse) => {
                if (jsonResponse.status == 200) {
                    commit("setDialogInfoCompl", jsonResponse.data);
                }
            })
            .catch((erreur) => {
                console.log("Error fetchDialogInfoCompl catch : " + erreur);
            });
        dispatch("TurtleApi/fetchDialogSugg", [], { root: true })
            .then((jsonResponse) => {
                if (jsonResponse.status == 200) {
                    commit("setDialogSugg", jsonResponse.data);
                }
            })
            .catch((erreur) => {
                console.log("Error fetchDialogSugg catch : " + erreur);
            });
        dispatch("TurtleApi/fetchDialogMaterial", [], { root: true })
            .then((jsonResponse) => {
                if (jsonResponse.status == 200) {
                    commit("setDialogMaterial", jsonResponse.data);
                }
            })
            .catch((erreur) => {
                console.log("Error fetchDialogMaterial catch : " + erreur);
            });
    },
};

const mutations = {
    setContent(state, content) {
        state.content = content.content.rendered;
    },
    setDialogInfoCompl(state, dialogInfoCompl) {
        state.dialogInfoCompl = dialogInfoCompl.content.rendered;
    },
    setDialogSugg(state, dialogSugg) {
        state.dialogSugg = dialogSugg.content.rendered;
    },
    setDialogMaterial(state, dialogMaterial) {
        state.dialogMaterial = dialogMaterial.content.rendered;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
