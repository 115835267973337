<template>
    <div id="circleUnivMenuPrincipal">
        <div id="circleUnivMenuContainSeason">
            <v-img
                id="circleUnivMenuArrow"
                :src="require('../assets/img/nav/arrow/rightArrow.svg')"
                contain
                width="7vmin"
                height="5vmin"
            ></v-img>
            <span v-if="this.activeSeason == ''" class="circleUnivMenuSeason">
                DÉFINIR UNE SAISON
            </span>
            <span v-if="this.activeSeason != ''" class="circleUnivMenuSeason">
                CHOISIR UNE THÉMATIQUE
                <dialogModal
                    widthDialog="90%"
                    cssName="dialogThematic"
                    title="Comprendre les thématiques"
                    :contents="this.thematics"
                    :dialogOpen="dialogCall"
                    @dialogClose="dialogCall = false"
                />
                <v-btn id="dialogIcon" icon @click.stop="dialogCall = true">
                    <v-icon>mdi-information</v-icon>
                </v-btn>
            </span>
        </div>
        <v-skeleton-loader
            class="circleUnivMenuItems"
            v-if="this.activeSeason != '' && this.thematics == undefined"
            type="article"
        ></v-skeleton-loader>
        <div
            v-if="this.activeSeason != '' && this.thematics != undefined"
            class="circleUnivMenuItems"
        >
            <div v-for="(thematic, keyArr) in this.thematics" :key="keyArr">
                <div
                    v-if="
                        activeThematic == undefined ||
                        activeThematic != thematic.id
                    "
                    id="circleUnivMenuItem"
                    @click="thematicClick(thematic.id)"
                    @mouseover="hoverThematic(thematic.id)"
                    @mouseout="outThematic()"
                    v-html="thematic.name"
                ></div>
                <div
                    v-if="activeThematic == thematic.id"
                    id="circleUnivMenuItemActive"
                    @click="thematicClick(thematic.id)"
                    @mouseover="hoverThematic(thematic.id)"
                    @mouseout="outThematic()"
                    v-html="thematic.name"
                ></div>
            </div>
        </div>
    </div>
</template>

<style>
#circleUnivMenuContainSeason {
    letter-spacing: 8px;
    display: flex;
    flex-flow: row;
    align-items: center;
    position: relative;
    left: -11%;
    width: 80%;
    margin-top: 40px;
}
#circleUnivMenuArrow {
    margin-right: 2%;
}
.circleUnivMenuSeason {
    font-family: "Montserrat-medium";
    color: #c82123;
    font-size: 2.5vmin;
    letter-spacing: 2px;
    width: 90%;
}
.circleUnivMenuItems {
    width: 70%;
}
#circleUnivMenuItem {
    font-family: "Montserrat-Black";
    color: #a78a7f;
    font-size: 2.5vmin;
    line-height: 200%;
    cursor: pointer;
    width: 100%;
}
#circleUnivMenuItem:hover {
    color: #745852;
}
#circleUnivMenuItemActive {
    font-family: "Montserrat-Black";
    color: #745852;
    font-size: 2.5vmin;
    line-height: 200%;
    cursor: pointer;
    width: 90%;
}
#dialogIcon {
    color: #c82123;
}
#dialogThematicTitle {
    margin: 20px;
    font-family: "Cinzel-Black";
    color: #a78a7f;
    text-transform: uppercase;
    font-size: 3vmin;
}
#dialogThematicSubTitle {
    font-family: "Montserrat-Light";
    color: #745852;
    font-size: 2.5vmin;
    text-transform: uppercase;
    margin: 20px;
}
#dialogThematicText {
    font-family: "SourceSerifPro-Regular";
    color: #745852;
    font-size: 2vmin;
    width: 75%;
    margin: 20px;
}
</style>

<script>
import { mapState } from "vuex";
import store from "../store";
import router from "../router";
import dialogModal from "./DialogModal";

export default {
    name: "CircleUnivMenu",

    data() {
        return {
            dialogCall: false,
        };
    },

    components: {
        dialogModal: dialogModal,
    },

    mounted() {
        store.dispatch("Thematics/getThematics", { root: true });
    },

    computed: {
        ...mapState({
            activeSeason: (state) => state.Seasons.activeSeason,
            thematics: (state) => state.Thematics.thematics,
            activeThematic: (state) => state.Thematics.activeThematic,
        }),
    },

    methods: {
        thematicClick(idThematic) {
            store.dispatch("Thematics/select", idThematic);
            router.push("/TimeLine");
        },
        hoverThematic(idThematic) {
            store.dispatch("Thematics/circleHover", idThematic);
        },
        outThematic() {
            store.dispatch("Thematics/circleHover", undefined);
        },
    },
};
</script>
