const state = {
    baseFields: "?_fields[]=name&_fields[]=id&_fields[]=slug",
};

const getters = {};

const actions = {
    fetchThematics({ state, dispatch }, params) {
        let strParams = state.baseFields;
        if (params != undefined && params.lenght > 0) {
            strParams += "&_fields[]=" + params.join("&_fields[]=");
        }
        return dispatch("Api/get", ["thematique" + strParams, {}], {
            root: true,
        });
    },
    fetchThematic({ state, dispatch }, [id, params]) {
        let strParams = state.baseFields;
        if (params != undefined && params.lenght > 0) {
            strParams += "&_fields[]=" + params.join("&_fields[]=");
        }
        return dispatch("Api/get", ["thematique/" + id + strParams], {
            root: true,
        });
    },
    fetchThematicDescription({ dispatch }, [id, params]) {
        return dispatch("PostApi/fetchPost", [id, params], {
            root: true,
        });
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
