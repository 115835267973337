<template>
    <div class="windowSize" id="womanContainerPrincipal">
        <div id="womanContainerTextImg">
            <div id="womanContainterImg">
                <v-img
                    id="womanImgWater"
                    contain
                    :src="require('../assets/img/water.svg')"
                >
                    <v-img
                        id="womanImgTurtle"
                        contain
                        :src="require('../assets/img/turtle.svg')"
                    >
                        <v-img
                            id="womanImgWoman"
                            contain
                            :src="require('../assets/img/woman.svg')"
                        ></v-img>
                    </v-img>
                </v-img>
            </div>
            <div id="womanContainerText">
                <span id="womanTitle">yäa’tayenhtsihk</span>
                <span id="womanSubTitle">LA FEMME TOMBÉE DU CIEL</span>
                <v-skeleton-loader
                    v-if="this.content == ''"
                    type="article"
                    class="womanText"
                ></v-skeleton-loader>
                <p class="womanText" v-html="this.content"></p>
                <div id="womanKnowMore">
                    <v-dialog
                        v-model="dialog"
                        content-class="womanDialog"
                        hide-overlay
                        transition="dialog-top-transition"
                        align="center"
                        width="1300"
                        height="800"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <span
                                id="womanKnowMoreText"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-img
                                    id="womanKnowMoreImg"
                                    contain
                                    :src="
                                        require('../assets/img/nav/+signWomen.svg')
                                    "
                                ></v-img>
                                EN SAVOIR PLUS
                            </span>
                        </template>

                        <div id="womanDialogBorderExterne">
                            <div id="womanDialogBorderInterne">
                                <div id="womanDialogTopDiv">
                                    <div id="womanDialogTopLeftDiv">
                                        <span class="womanDialogTitle">
                                            Informations complémentaire
                                        </span>
                                        <v-skeleton-loader
                                            v-if="this.dialogInfoCompl == ''"
                                            type="article"
                                            class="womanDialogLeftText"
                                        ></v-skeleton-loader>
                                        <p
                                            class="womanDialogLeftText"
                                            v-html="this.dialogInfoCompl"
                                        ></p>
                                        <div id="womanDialogLeftImgDiv">
                                            <v-img
                                                id="womanDialogLeftImg"
                                                contain
                                                :src="
                                                    require('../assets/img/tortueIllus.png')
                                                "
                                                width="15vmin"
                                                height="11vmin"
                                            ></v-img>
                                        </div>
                                    </div>

                                    <div id="womanDialogTopRightDiv">
                                        <span class="womanDialogTitle">
                                            Suggestion d’activités
                                        </span>
                                        <v-skeleton-loader
                                            v-if="this.dialogSugg == ''"
                                            type="article"
                                            class="womanDialogRightText"
                                        ></v-skeleton-loader>
                                        <p
                                            class="womanDialogRightText"
                                            v-html="this.dialogSugg"
                                        ></p>
                                    </div>
                                </div>
                                <div id="womanDialogBottomDiv">
                                    <v-img
                                        id="womanDialogBottomImgLeft"
                                        contain
                                        :src="
                                            require('../assets/img/iconMateriel.svg')
                                        "
                                        width="12.5vmin"
                                        height="12.5vmin"
                                    ></v-img>

                                    <div id="womanDialogBottomCenter">
                                        <span class="womanDialogTitle">
                                            Matériel
                                        </span>
                                        <v-skeleton-loader
                                            v-if="this.dialogMaterial == ''"
                                            type="article"
                                            class="womanDialogBottomText"
                                        ></v-skeleton-loader>
                                        <p
                                            class="womanDialogBottomText"
                                            v-html="this.dialogMaterial"
                                        ></p>
                                    </div>
                                    <v-img
                                        id="womanDialogBottomImgRight"
                                        contain
                                        :src="
                                            require('../assets/img/visitezCulturePatrimoine.svg')
                                        "
                                        width="20vmin"
                                        height="20vmin"
                                    ></v-img>
                                </div>
                            </div>
                        </div>

                        <div id="womanDialogBtnsEnters" @click="dialog = false">
                            <v-img
                                id="womanDialogImgRightArrow"
                                contain
                                :src="
                                    require('../assets/img/nav/arrow/leftArrow.svg')
                                "
                                width="9vmin"
                                height="5vmin"
                            >
                            </v-img>
                            <div id="womanDialogBtnsEntersText">
                                <span id="womanDialogBtnEnterFr">RETOUR</span>
                            </div>
                        </div>
                    </v-dialog>
                </div>
            </div>
            <div id="womanBtnsEnters" @click="$router.push('/circleUniv')">
                <div id="womanBtnsEntersText">
                    <span id="womanBtnEnterFr">LA TORTUE</span>
                </div>
                <v-img
                    id="womanImgRightArrow"
                    contain
                    :src="require('../assets/img/nav/arrow/downArrow.svg')"
                    width="5vmin"
                    height="10vmin"
                >
                </v-img>
            </div>
        </div>
        <appFooter :mode="1"></appFooter>
    </div>
</template>

<style>
#womanContainerPrincipal {
}
#womanContainerTextImg {
    display: flex;
    flex-flow: center;
    align-items: center;
    height: 85%;
}
#womanContainterImg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    height: 100%;
}
#womanImgWater {
    width: 100%;
}
#womanImgTurtle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 65%;
}
#womanImgWoman {
    margin: auto;
    left: -1vmin;
    width: 35%;
}
#womanImgWater {
    display: flex;
    align-items: center;
    justify-content: center;
}
#womanContainerText {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 10px;
    width: 45%;
}
#womanTitle {
    font-family: "Vollkorn-SemiBoldItalic";
    color: #745852;
    font-size: 6vmin;
}
#womanSubTitle {
    font-family: "Montserrat-Light";
    color: #745852;
    font-size: 3.5vmin;
    text-transform: uppercase;
}
.womanText {
    text-align: center;
    font-family: "SourceSerifPro-Regular";
    color: #745852;
    font-size: 2vmin;
    margin-top: 5%;
    width: 85%;
}
#womanKnowMore {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 50px;
}
#womanKnowMore:hover {
    opacity: 0.65;
    transition-duration: 250ms;
}
#womanKnowMoreImg {
    width: 25px;
    margin-right: 7.5px;
}
#womanKnowMoreText {
    display: flex;
    flex-flow: row;
    align-items: center;
    font-family: "Montserrat-Black";
    color: #745852;
    font-size: 1.5vmin;
    margin-left: 10px;
    letter-spacing: 2px;
}
#womanBtnsEnters {
    display: flex;
    flex-flow: column;
    align-items: center;
    position: absolute;
    top: 35%;
    right: 15px;
    width: 10%;
    cursor: pointer;
    background-color: white;
}
#womanBtnEnterFr {
    font-family: "Montserrat-Black";
    color: #a78a7f;
    font-size: 15pt;
    text-transform: uppercase;
    font-size: 1.5vmin;
    letter-spacing: 1.5px;
}
#womanImgRightArrow {
    margin: 10px;
}

/***** Dialog *****/
.womanDialog {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
#womanDialogBorderExterne {
    position: absolute;
    top: 15px;
    left: 5%;
    margin: 0 auto;
    padding: 4px;
    background-color: white;
    border: #e7d7c2 solid 2.5px;
    width: 92%;
    height: 85%;
}
#womanDialogBorderInterne {
    display: flex;
    flex-flow: column;
    align-items: center;
    border: #e7d7c2 solid 5.5px;
    padding: 30px;
    height: 100%;
}
.womanDialogTitle {
    font-family: "Cinzel-Black";
    color: #a78a7f;
    font-size: 2.5vmin;
}
#womanDialogTopDiv {
    display: flex;
    flex-flow: row;
    width: 100%;
    height: 70%;
}
#womanDialogTopLeftDiv {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 4% 4% 0% 4%;
    margin: 1%;
    width: 50%;
}
.womanDialogLeftText {
    font-family: "SourceSerifPro-Regular";
    color: #745852;
    font-size: 1.6vmin;
    margin-top: 4%;
    text-align: center;
    width: 90%;
}
#womanDialogLeftImgDiv {
    display: flex;
    align-items: center;
}
#womanDialogLeftImg {
    width: 5%;
}
#womanDialogTopRightDiv {
    display: flex;
    flex-flow: column;
    background-image: url("../assets/img/cadreActivités.svg");
    margin-top: 2%;
    padding: 5% 1% 1% 1%;
    width: 50%;
}
.womanDialogRightText {
    text-transform: uppercase;
    font-family: "Montserrat-SemiBold";
    color: #a78a7f;
    font-size: 1.3vmin;
    width: 72%;
    line-height: 1.5vmin;
    text-transform: uppercase;
}
.womanDialogRightText li {
    margin-top: 10px;
}
#womanDialogBottomDiv {
    display: flex;
    flex-flow: row;
    align-items: center;
    background-color: #f9f5f0;
    padding: 30px;
    width: 95%;
    height: 30%;
}
#womanDialogBottomCenter {
    padding: 30px;
    width: 80%;
}
.womanDialogBottomTitle {
    font-family: "Cinzel-Black";
    color: #a78a7f;
    font-size: 2vmin;
    margin: 20px;
}
.womanDialogBottomText {
    font-family: "Montserrat-SemiBold";
    color: #a78a7f;
    font-size: 1.5vmin;
}
.womanDialogBottomText li {
    margin-top: 10px;
}
.womanDialogBottomText li a {
    text-decoration: none;
    font-family: "Montserrat-SemiBold";
    color: #a78a7f;
}
#womanDialogBtnsEnters {
    display: flex;
    flex-flow: row;
    align-items: center;
    position: absolute;
    top: 40%;
    left: 0px;
    width: 10%;
    height: 10%;
    cursor: pointer;
    background-color: white;
}
#womanDialogBtnEnterFr {
    font-family: "Montserrat-Black";
    color: #a78a7f;
    font-size: 2vmin;
}
#womanDialogImgRightArrow {
    margin: 5px;
    width: 5%;
}
</style>

<script>
import { mapState } from "vuex";
import store from "../store";

import appFooter from "./appFooter";

export default {
    name: "Woman",

    data() {
        return { dialog: false };
    },

    components: {
        appFooter: appFooter,
    },

    mounted() {
        store.dispatch("Woman/getWomanDatas");
    },

    computed: {
        ...mapState({
            content: (state) => state.Woman.content,
            dialogInfoCompl: (state) => state.Woman.dialogInfoCompl,
            dialogSugg: (state) => state.Woman.dialogSugg,
            dialogMaterial: (state) => state.Woman.dialogMaterial,
        }),
    },
};
</script>
