import axios from "axios";

const state = {
    callInProgress: [],
    //baseUrl: "https://staging.yandata.ca/wp-json/wp/v2/",
    baseUrl: "https://universwendat.com/wp-json/wp/v2/",
};

const getters = {};

const actions = {
    //----  GET
    get({ dispatch, state, commit }, [url, datas, headersSup]) {
        axios.defaults.baseURL = state.baseUrl;
        if (state.callInProgress.indexOf(url) < 0) {
            commit("pushInCallInProgress", url);
            let headers = { ...headersSup };

            return axios
                .get(url, { headers })
                .then((jsonResponse) => {
                    commit("deleteInCallInProgress", url);
                    return jsonResponse;
                })
                .catch((error) => {
                    commit("deleteInCallInProgress", url);
                    return error.response;
                });
        } else {
            return false;
        }
    },

    //----  POST
    post({ dispatch, state, commit }, [url, datas, headersSup]) {
        console.log("post :: " + url);
        axios.defaults.baseURL = state.baseUrl;
        if (state.callInProgress.indexOf(url) < 0) {
            commit("pushInCallInProgress", url);
            let headers = { ...headersSup };

            return axios
                .post(url, datas, { headers })
                .then((jsonResponse) => {
                    commit("deleteInCallInProgress", url);
                    return jsonResponse;
                })
                .catch((error) => {
                    commit("deleteInCallInProgress", url);
                    return error.response;
                });
        } else {
            return false;
        }
    },

    //----  GET
    put({ dispatch, state, commit }, [url, datas, headersSup]) {
        console.log("put :: " + url);
        axios.defaults.baseURL = state.baseUrl;
        if (state.callInProgress.indexOf(url) < 0) {
            commit("pushInCallInProgress", url);
            let headers = { ...headersSup };

            return axios
                .post(url + "?_method=PUT", datas, { headers })
                .then((jsonResponse) => {
                    commit("deleteInCallInProgress", url);
                    return jsonResponse;
                })
                .catch((error) => {
                    commit("deleteInCallInProgress", url);
                    return error.response;
                });
        } else {
            return false;
        }
    },

    //----  DELETE
    destroy({ dispatch, state, commit }, [url, datas, headersSup]) {
        console.log("destroy :: " + url);
        axios.defaults.baseURL = state.baseUrl;
        if (state.callInProgress.indexOf(url) < 0) {
            commit("pushInCallInProgress", url);
            let headers = { ...headersSup };

            return axios
                .post(url + "?_method=DELETE", datas, { headers })
                .then((jsonResponse) => {
                    commit("deleteInCallInProgress", url);
                    return jsonResponse;
                })
                .catch((error) => {
                    commit("deleteInCallInProgress", url);
                    return error.response;
                });
        } else {
            return false;
        }
    },
};

const mutations = {
    setBusy(state, busy) {
        state.busy = busy;
    },

    pushInCallInProgress(state, url) {
        state.callInProgress.push(url);
    },

    deleteInCallInProgress(state, url) {
        let idx = state.callInProgress.indexOf(url);
        if (idx >= 0) {
            state.callInProgress.splice(idx, 1);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
