<template>
    <div class="windowSize" id="homePrincipal">
        <v-img id="homeImg" contain :src="require('../assets/img/home.svg')">
        </v-img>
        <div id="homeBtnsEnters" @click="$router.push('/reference')">
            <div id="homeBtnsEntersText">
                <span id="homeBtnEnter">tsionh!</span>
                <span id="homeBtnEnterFr">ENTREZ!</span>
            </div>
            <v-img
                id="homeImgRightArrow"
                position="center center"
                contain
                :src="require('../assets/img/nav/arrow/rightArrow.svg')"
                width="10vmin"
                height="5vmin"
            >
            </v-img>
        </div>
        <appFooter></appFooter>
    </div>
</template>

<style scoped>
#homePrincipal {
}
#homeImg {
    height: 100%;
}
#homeBtnsEnters {
    display: flex;
    flex-flow: row;
    align-items: center;
    position: absolute;
    top: 45%;
    right: 5%;
    cursor: pointer;
}
#homeImgRightArrow {
    position: relative;
    left: 5px;
}

#homeBtnsEntersText {
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;
    top: 0px;
    left: 0px;
}
#homeBtnEnter {
    position: relative;
    display: block;
    font-family: "Vollkorn-SemiBoldItalic";
    text-transform: capitalize;
    color: #c82123;
    font-size: 5vmin;
}
#homeBtnEnterFr {
    position: relative;
    top: 0px;
    left: 0px;
    font-family: "Montserrat-Black";
    color: #a78a7f;
    text-transform: uppercase;
    font-size: 1.5vmin;
    letter-spacing: 1.5px;
}
</style>

<script>
import appFooter from "./appFooter";

export default {
    name: "Home",

    components: {
        appFooter: appFooter,
    },
};
</script>
