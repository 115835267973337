<template>
    <v-app id="app">
        <v-main>
            <v-container fluid id="containerApp">
                <div>
                    <router-view :key="$route.path"></router-view>
                </div>
            </v-container>
        </v-main>
    </v-app>
</template>

<style>
#app {
    overflow: hidden;
}
#containerApp {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0px;
}
.windowSize {
    width: 100vw;
    height: 100vh;
}
@font-face {
    font-family: "Cinzel-Black";
    src: local("Cinzel-Black"),
        url(./assets/fonts/Cinzel-Black.ttf) format("truetype");
}
@font-face {
    font-family: "Cinzel-Bold";
    src: local("Cinzel-Bold"),
        url(./assets/fonts/Cinzel-Bold.ttf) format("truetype");
}
@font-face {
    font-family: "CinzelDecorative-Black";
    src: local("CinzelDecorative-Black"),
        url(./assets/fonts/CinzelDecorative-Black.ttf) format("truetype");
}
@font-face {
    font-family: "CinzelDecorative-Regular";
    src: local("CinzelDecorative-Regular"),
        url(./assets/fonts/CinzelDecorative-Regular.ttf) format("truetype");
}
@font-face {
    font-family: "Montserrat-Black";
    src: local("Montserrat-Black"),
        url(./assets/fonts/Montserrat-Black.ttf) format("truetype");
}
@font-face {
    font-family: "Montserrat-SemiBold";
    src: local("Montserrat-SemiBold"),
        url(./assets/fonts/Montserrat-SemiBold.ttf) format("truetype");
}
@font-face {
    font-family: "Montserrat-Light";
    src: local("Montserrat-Light"),
        url(./assets/fonts/Montserrat-Light.ttf) format("truetype");
}
@font-face {
    font-family: "Montserrat-Medium";
    src: local("Montserrat-Medium"),
        url(./assets/fonts/Montserrat-Medium.ttf) format("truetype");
}
@font-face {
    font-family: "SourceSerifPro-Regular";
    src: local("SourceSerifPro-Regular"),
        url(./assets/fonts/SourceSerifPro-Regular.ttf) format("truetype");
}
@font-face {
    font-family: "Vollkorn-SemiBoldItalic";
    src: local("Vollkorn-SemiBoldItalic"),
        url(./assets/fonts/Vollkorn-SemiBoldItalic.ttf) format("truetype");
}
</style>

<script>
export default {
    name: "app",
};
</script>
