const state = {
    circleHover: undefined,
    circleBase: require("../../assets/img/circles/base.svg"),
    //circleBase: require("../../assets/img/circles/base.png"),
    seasons: {
        winter: {
            top: "16%",
            left: "29%",
            width: "43%",
            height: "15%",
        },
        spring: {
            top: "32%",
            left: "68%",
            width: "20%",
            height: "33%",
        },
        summer: {
            top: "67%",
            left: "29%",
            width: "43%",
            height: "15%",
        },
        autumn: {
            top: "32%",
            left: "13%",
            width: "20%",
            height: "33%",
        },
    },
};

const getters = {};

const actions = {
    changeHover({ commit, rootState }, activeSeason) {
        let imgActive = undefined;
        if (activeSeason != undefined) {
            imgActive = rootState.Seasons.seasons[activeSeason].src;
        } else if (rootState.Seasons.activeSeason != "") {
            imgActive =
                rootState.Seasons.seasons[rootState.Seasons.activeSeason].src;
        }
        commit("setCircleHover", imgActive);
    },
};

const mutations = {
    setCircleHover(state, imgActiveSeason) {
        state.circleHover = imgActiveSeason;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
