var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"windowSize",attrs:{"id":"activityBrief"}},[_c('div',{style:({
            'background-color': this.activeThematicColor,
        }),attrs:{"id":"activityBriefThematicTitle"},domProps:{"innerHTML":_vm._s(this.activeThematicName)}}),_c('div',{attrs:{"id":"activityBriefContent"}},[_c('div',{attrs:{"id":"activityBriefContentLeft"}},[(this.urlImage1 != false)?_c('div',{style:({
                    'background-image': 'url(' + this.urlImage1 + ')',
                }),attrs:{"id":"activityBriefLeftImg1"}}):_vm._e(),(this.urlImage2 != false)?_c('div',{style:({
                    'background-image': 'url(' + this.urlImage2 + ')',
                }),attrs:{"id":"activityBriefLeftImg2"}}):_vm._e(),(this.urlImage3 != false)?_c('div',{style:({
                    'background-image': 'url(' + this.urlImage3 + ')',
                }),attrs:{"id":"activityBriefLeftImg3"}}):_vm._e()]),_c('div',{attrs:{"id":"activityBriefContentCenter"}},[_c('div',{attrs:{"id":"activityBriefRightTitle"},domProps:{"innerHTML":_vm._s(this.title)}}),_c('div',{attrs:{"id":"activityBriefRightSubTitle"},domProps:{"innerHTML":_vm._s('De ' + this.periodesNames)}}),(this.excerpt == undefined)?_c('v-skeleton-loader',{staticClass:"activityBriefRightText",attrs:{"type":"article"}}):_vm._e(),_c('div',{staticClass:"activityBriefRightText",domProps:{"innerHTML":_vm._s(this.excerpt)}}),_c('div',{attrs:{"id":"activityBriefRightKnowMore"},on:{"click":function($event){return _vm.$router.push('/activityDetail')}}},[_c('v-img',{attrs:{"id":"activityBriefKnowMoreImg","contain":"","src":this.activeThematicSign}}),_c('span',{style:({
                        color: this.activeThematicColor,
                    }),attrs:{"id":"activityBriefKnowMoreText"}},[_vm._v(" EN SAVOIR PLUS ")])],1)],1),_c('div',{attrs:{"id":"activityBriefContentRight"}})]),_c('appFooter',{attrs:{"mode":3}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }